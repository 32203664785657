import React from 'react';
import { Link } from 'react-router-dom';
import { AttachMoney } from '@material-ui/icons';
import { Container, Content, Header, Title, Card, Cards } from './styles';

import Free from '../../assets/landing/free.svg';
import Starter from '../../assets/landing/starter.svg';
import Pro from '../../assets/landing/pro.svg';

const Plans = () => (
  <Container>
    <Content>
      <Header>
        <Title>
          <h3>
            Contrate um dos planos abaixo para criar projetos na sua organização
          </h3>
        </Title>
        <AttachMoney />
      </Header>
      <Cards>
        <Card>
          <img src={Free} alt="Free icon" />
          <h1>Free</h1>
          <p>Crie até 5 projetos gratuitamente</p>
          <Link to="payment/free">
            <button type="button">Comece a utilizar</button>
          </Link>
        </Card>
        <Card>
          <img src={Starter} alt="Starter icon" />
          <h1>Starter</h1>
          <p>Crie até 20 projetos por R$ 49,90</p>
          <Link to="/payment/starter">
            <button type="button">Comece a utilizar</button>
          </Link>
        </Card>
        <Card>
          <img src={Pro} alt="Pro icon" />
          <h1>Pro</h1>
          <p>Crie até 100 projetos por R$ 99,90</p>
          <Link to="/payment/pro">
            <button type="button">Comece a utilizar</button>
          </Link>
        </Card>
      </Cards>
    </Content>
  </Container>
);

export default Plans;
