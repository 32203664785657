import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div {
    margin-left: 16px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Paragraph = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60vh;

  h1 {
    text-align: center;
    font-size: 22px;
    color: #312e38;
    display: flex;
    align-items: center;

    svg {
      margin-left: 20px;
    }
  }

  a {
    margin-top: 15px;
    color: #ff9000;
    font-size: 18px;
    font-weight: 200;
    display: flex;
    align-items: center;
    text-decoration: none;

    svg {
      margin-left: 18px;
    }
  }
`;

export const NewProject = styled.div`
  display: flex;
  align-items: center;
  margin-right: 35px;

  strong {
    color: #312e38;
    margin-right: 8px;
    font-size: 18px;
    transition: color ease-in 0.2s;

    &:hover {
      color: #555;
    }
  }
  a {
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff6600;
    border-radius: 50%;
  }
`;

export const SearchProjects = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;

  input {
    margin-bottom: 20px;
    background: #f3f3f3;
    padding: 0 15px;
    height: 40px;
    width: 380px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow ease-in 0.2s;

    &::placeholder {
      color: #7c758d;
    }

    &:hover {
      box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
    }
  }

  button {
    margin-bottom: 20px;
    margin-left: 10px;
    border: none;
    background: #5952ff;
    height: 40px;
    border-radius: 8px;
    padding: 0 15px;

    svg {
      color: #e7e7e7;
    }
  }
`;

export const ProjectHeader = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-bottom: 1px solid #ddd;

  padding-bottom: 20px;

  div.css-2b097c-container {
    width: 200px;
  }

  button {
    background-color: transparent;
    color: #312e38;

    display: flex;
    align-items: center;
    border: none;
    transition: all ease-in 0.4s;

    &:hover {
      color: #444;
    }

    svg {
      margin-left: 12px;
    }
  }
`;

export const ModalContent = styled.div`
  max-height: 300px;
  min-height: 150px;
  height: 100%;

  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  padding: 20px 0;

  color: #444;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  width: 100%;
  margin-bottom: 20px;

  h1 {
    font-weight: 400;
    font-size: 16px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;

  padding: 10px;

  border-radius: 4px;

  max-width: 320px;
  width: 100%;
  margin: 0 auto;

  svg {
    color: #5b5fff;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  max-width: 320px;
  width: 100%;
  margin: 0 auto;
`;

export const Button = styled.button`
  height: 35px;
  border: 0;
  width: 300px;
  background: ${({ theme, cancel }) =>
    cancel ? 'none' : theme.colors.secondary};
  color: ${({ theme, cancel }) => (cancel ? theme.colors.secondary : '#fff')};
  font-weight: bold;
  font-size: 16px;
  margin-top: 50px;
  margin-left: 8px;
  border-radius: 4px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `};

  &:hover {
    opacity: ${props => (props.isDisabled ? 0.4 : 0.7)};
  }
`;
