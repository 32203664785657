import React, { useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../provider/hooks/auth';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  CreateAccount,
} from './styles';

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { signIn } = useAuth();
  const formRef = useRef(null);

  const handleSignIn = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = data;
        setLoading(true);
        await signIn(email, password);
        history.push('/home-screen');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        console.log(err);
        toast.error('Erro ao fazer login, cheque suas credenciais');
        setLoading(false);
      }
    },
    [signIn, history]
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSignIn}>
            <h1>Bem vindo</h1>
            <p>Faça seu login para poder utilizar nossa plataforma</p>
            <Input placeholder="Email" type="email" name="email" />

            <Input
              placeholder="Senha"
              isPassword
              type="password"
              name="password"
            />

            <button type="submit">
              {loading ? 'Carregando...' : 'Entrar'}
            </button>
            <Link to="/forgot-password">Esqueci minha senha</Link>
            <CreateAccount>
              <span>Não tem uma conta?</span>
              <Link to="/signup">Cadastre-se</Link>
            </CreateAccount>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
