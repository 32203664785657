import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Canvas } from 'react-three-fiber';
import { useDrop } from 'react-dnd';
import {
  RemoveRedEye,
  AttachFile,
  Delete,
  Close,
  Visibility,
  Save,
  Share,
  ExpandMore,
} from '@material-ui/icons';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import update from 'immutability-helper';

import firestore from '../../config/firestore';
import storage from '../../config/storage';
import Scene from './Scene';
import Controls from './Controls';

import {
  storageImagesURL,
  storageProject,
  storageProjectTitle,
  storageProjectCode,
} from '../../constants/storage';

import AppProvider from '../../provider';
import { useEvent } from '../../provider/hooks/events';
import { useAuth } from '../../provider/hooks/auth';

import Preview from './components/Preview';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';

import {
  FloatingContainer,
  FloatingCard,
  HotspotModal,
  ModalHeader,
  ModalContent,
  ModalDisabledButton,
  ModalInputArea,
  Container,
  Header,
  HeaderFileInput,
  SaveButton,
  HorizontalWrap,
  Sidebar,
  View,
} from './styles';

import './components/Preview/animation.css';

const Editor = () => {
  const [initialUpdateFormData, setInitialUpdateFormData] = useState('');
  const [currentEditedIndex, setCurrentEditedIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [saveButtonModal, setSaveButtonModal] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [, setDidDrop] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasThumbnail, setHasThumbnail] = useState(false);
  const [currentHotspotName, setCurrentHotspotName] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [projectName, setProjectName] = useState(
    localStorage.getItem(storageProjectTitle)
  );
  const [projectDescription, setProjectDescription] = useState('');

  const [code] = useState(localStorage.getItem(storageProjectCode));
  const [imageUrls] = useState(
    JSON.parse(localStorage.getItem(storageImagesURL))
  );

  const [images, setImages] = useState(() => {
    const imagesWithUrlsAndLinks = imageUrls.map(image => ({
      image: image.url,
      label: image.label,
      links: image.links || [],
      missingHotspots: image.missingHotspots || [],
    }));

    return imagesWithUrlsAndLinks;
  });

  const { getEvent, addEvent } = useEvent();
  const { organization } = useAuth();

  const history = useHistory();

  const organizationName = useMemo(() => {
    const { name } = organization.data;

    return name;
  }, [organization]);

  const formattedOrgName = useMemo(() => {
    const { name } = organization.data;

    const nameLowerCase = name.toLowerCase();

    const organizationName = nameLowerCase.replace(/\s/g, '-');

    return organizationName;
  }, [organization]);

  const validateIsMissingHotspot = useCallback(
    (itemIndex, isDropping) => {
      const allImageLinks = images[itemIndex].links.map(link => link.to);
      const imageMissingHotspots = images[itemIndex].missingHotspots;

      if (
        !allImageLinks.includes(currentIndex) &&
        !imageMissingHotspots.includes(images[currentIndex].label)
      ) {
        // Remove this line after done
        toast.info(
          'Certifique-se de que há um hotspot de volta para esta imagem'
        );

        const imageWithMissingHotspot = images[itemIndex];
        const currentImageLabel = images[currentIndex].label;

        if (
          !imageWithMissingHotspot.missingHotspots.includes(currentImageLabel)
        ) {
          imageWithMissingHotspot.missingHotspots.push(currentImageLabel);

          const newImages = images;
          newImages[itemIndex] = imageWithMissingHotspot;

          setImages(newImages);
        }
      } else if (isDropping) {
        const imageToRemoveMissingHotspot = images[currentIndex];
        const droppedImageLabel = images[itemIndex].label;

        const imageMissingHotspotsUpdated = imageToRemoveMissingHotspot.missingHotspots.filter(
          missing => missing !== droppedImageLabel
        );

        imageToRemoveMissingHotspot.missingHotspots = imageMissingHotspotsUpdated;

        const newImages = images;
        newImages[currentIndex] = imageToRemoveMissingHotspot;

        setImages(newImages);
      } else {
        const imageToRemoveMissingHotspot = images[itemIndex];
        const droppedImageLabel = images[currentIndex].label;

        const imageMissingHotspotsUpdated = imageToRemoveMissingHotspot.missingHotspots.filter(
          missing => missing !== droppedImageLabel
        );

        imageToRemoveMissingHotspot.missingHotspots = imageMissingHotspotsUpdated;

        const newImages = images;
        newImages[itemIndex] = imageToRemoveMissingHotspot;

        setImages(newImages);
      }
    },
    [currentIndex, images]
  );

  const handleDeleteHotspot = useCallback(() => {
    const newLinks = images[currentIndex].links.filter(
      (link, index) => index !== currentEditedIndex
    );

    let deletedHotspotLinkTo = images[currentIndex].links.map((link, index) => {
      if (index === currentEditedIndex) {
        return link.to;
      }
    });

    deletedHotspotLinkTo = deletedHotspotLinkTo.filter(d => !!d || d === 0);

    const imageWithoutDeletedHotspot = images[currentIndex];
    imageWithoutDeletedHotspot.links = newLinks;

    const newImages = images;
    newImages[currentIndex] = imageWithoutDeletedHotspot;
    setImages(newImages);

    validateIsMissingHotspot(deletedHotspotLinkTo[0], false);

    setShowModal(false);
    setDidDrop(false);
  }, [currentEditedIndex, images, currentIndex, validateIsMissingHotspot]);

  const onClickHotspot = useCallback(
    index => {
      setShowModal(!showModal);
      setCurrentEditedIndex(index);

      const currentImageLinks = images[currentIndex].links;
      const toImageIndex = currentImageLinks[index].to;
      const toImageLabel = images[toImageIndex].label;
      setCurrentHotspotName(toImageLabel);
    },
    [showModal, currentIndex, images]
  );

  const handleUpload = useCallback(
    e => {
      const storageRef = storage.ref();
      const file = e.target.files[0];
      const imageRef = storageRef.child(file.name);

      const uploadTask = imageRef.put(file);

      toast.info('Adicionando nova imagem, aguarde alguns instantes');
      uploadTask.on(
        'state_changed',
        () => {},
        () => {
          toast.error('Ocorreu um erro ao adicionar a imagem. Tente novamente');
        },
        async () => {
          const image = await imageRef.getDownloadURL();
          const myFile = {
            url: image,
            image,
            links: [],
            label: file.name,
          };
          setImages([...images, myFile]);

          const newImages = [
            ...JSON.parse(localStorage.getItem(storageImagesURL)),
            myFile,
          ];

          localStorage.setItem(storageImagesURL, JSON.stringify(newImages));
        }
      );
    },
    [images]
  );

  const move = useCallback(
    (dragIndex, hoverIndex) => {
      const dragImage = images[dragIndex];

      setImages(
        update(images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragImage],
          ],
        })
      );
    },
    [images]
  );

  const moveUp = useCallback(
    index => {
      if (index === 0) {
        return;
      }

      move(index, index - 1);
    },
    [move]
  );

  const moveDown = useCallback(
    index => {
      if (index === images.length - 1) {
        return;
      }

      move(index, index + 1);
    },
    [images, move]
  );

  const [, dropRef] = useDrop({
    accept: 'IMAGE',
    drop(item) {
      if (item.index !== currentIndex) {
        setTimeout(() => {
          const event = getEvent();

          if (event) {
            const { point } = event;
            const { rotation } = event.camera;

            const currentImageLinks = images[currentIndex].links.map(
              link => link
            );

            if (currentImageLinks.length > 0) {
              const toOfCurrentImageLinks = currentImageLinks.map(
                link => link.to
              );

              if (toOfCurrentImageLinks.includes(item.index)) {
                return toast.error('Já existe um caminho para esse ambiente');
              }
            }

            currentImageLinks.push({
              to: item.index,
              position: point,
              rotation: { x: rotation.x, y: rotation.y, z: rotation.z },
            });

            const newImages = images;
            newImages[currentIndex].links = currentImageLinks;

            setImages(newImages);

            validateIsMissingHotspot(item.index, true);

            setDidDrop(true);
            setDidDrop(false);
          }
        }, 500);
      }
    },
  });

  const handleSaveTour = useCallback(
    async saveAndPublish => {
      const tourID = localStorage.getItem(storageProject);
      const tourRef = firestore.collection('tours360').doc(tourID);

      const response = await tourRef.get();
      let { description } = response.data();

      if (projectDescription !== '') {
        description = projectDescription;
      }

      tourRef.set(
        {
          images: JSON.stringify(images),
          name: projectName,
          publicado: false,
          description,
          canPublish: false,
        },
        { merge: true }
      );

      if (saveAndPublish) {
        tourRef.set(
          {
            canPublish: true,
            publicado: true,
          },
          { merge: true }
        );

        const el = document.createElement('textarea');
        el.value = `https://home360.com.br/view/${formattedOrgName}/${code}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast.success(
          'Link de compartilhamento copiado para área de transferência!'
        );
      }

      history.push('/dashboard');
      window.location.reload();
      localStorage.removeItem(storageImagesURL);
      localStorage.removeItem(storageProject);
      localStorage.removeItem(storageProjectTitle);
    },
    [images, code, formattedOrgName, history, projectName, projectDescription]
  );

  const handleOpenSaveModal = useCallback(() => {
    if (!hasThumbnail) {
      toast.error('Selecione uma imagem como capa do projeto');
      return;
    }

    const linksValidation = images.map(({ links }) => links.length > 0);
    const missingHotspotsValidation = images.map(
      ({ missingHotspots }) => missingHotspots.length <= 0
    );

    if (
      !linksValidation.includes(false) &&
      !missingHotspotsValidation.includes(false)
    ) {
      setCanPublish(true);
    }

    setSaveButtonModal(true);
  }, [images, hasThumbnail]);

  const onChangeLabel = useCallback(
    (index, text) => {
      images[index].label = text;
    },
    [images]
  );

  const handleSaveToPreview = useCallback(() => {
    const tourID = localStorage.getItem(storageProject);
    const tourRef = firestore.collection('tours360').doc(tourID);
    tourRef.set(
      {
        images: JSON.stringify(images),
      },
      { merge: true }
    );
  }, [images]);

  const handleExcludeImage = useCallback(
    ref => {
      const updatedImages = images.filter(image => {
        return image.label !== ref;
      });

      setImages(updatedImages);
      const projectRef = firestore
        .collection('tours360')
        .doc(localStorage.getItem(storageProject));

      projectRef.set(
        {
          images: JSON.stringify(updatedImages),
        },
        { merge: true }
      );
      localStorage.setItem(storageImagesURL, JSON.stringify(updatedImages));
    },
    [images]
  );

  const handleChooseThumbnail = useCallback(thumb => {
    const projectRef = firestore
      .collection('tours360')
      .doc(localStorage.getItem(storageProject));

    projectRef.set(
      {
        thumbNail: thumb,
      },
      { merge: true }
    );

    setHasThumbnail(true);
  }, []);

  const handleNavigateFromHotspot = useCallback(() => {
    setShowModal(false);

    const currentImageLinks = images[currentIndex].links;
    const toImageIndex = currentImageLinks[currentEditedIndex].to;
    setCurrentIndex(toImageIndex);
  }, [currentEditedIndex, currentIndex, images]);

  const sideRef = useRef(null);

  const scroll = useCallback(
    missing => {
      const index = images.findIndex(image => {
        return image.label === missing;
      });

      const currentCard = document.getElementById(index.toString());

      sideRef.current.scrollTo(0, currentCard.offsetTop - 5);

      const currentClassName = currentCard.className;
      const newClassName = `${currentClassName} animation`;

      currentCard.className = newClassName;
    },
    [images]
  );

  const handleUpdateDataProject = useCallback(data => {
    localStorage.setItem(storageProjectTitle, data.title);
    setProjectName(data.title);
    localStorage.setItem(storageProjectTitle, data.description);
    setProjectDescription(data.description);

    setEditModal(false);
  }, []);

  useEffect(() => {
    async function loadInitialData() {
      const response = await firestore
        .collection('tours360')
        .where('name', '==', projectName)
        .get();

      response.docs.forEach(doc =>
        setInitialUpdateFormData({
          title: doc.data().name,
          description: doc.data().description,
        })
      );
    }

    loadInitialData();
  }, [projectName]);

  const toggleModal = useCallback(() => {
    setEditModal(!editModal);
  }, [editModal]);

  return (
    <>
      {showModal && (
        <Modal isOpen>
          <HotspotModal>
            <ModalHeader>
              <strong>{currentHotspotName}</strong>
            </ModalHeader>
            <button onClick={() => setShowModal(false)} type="button">
              <Close />
            </button>
            <ModalContent>
              <button onClick={handleDeleteHotspot} type="button">
                <Delete color="secondary" />
                Remover
              </button>
              <button onClick={handleNavigateFromHotspot} type="button">
                <Visibility color="primary" />
                Ver ambiente
              </button>
            </ModalContent>
          </HotspotModal>
        </Modal>
      )}

      {saveButtonModal && (
        <Modal isOpen>
          <HotspotModal>
            <ModalHeader>
              <strong>Opções</strong>
            </ModalHeader>
            <button onClick={() => setSaveButtonModal(false)} type="button">
              <Close />
            </button>
            <ModalContent>
              <button onClick={() => handleSaveTour(false)} type="button">
                <Save color="secondary" />
                Salvar
              </button>
              {canPublish ? (
                <button onClick={() => handleSaveTour(true)} type="button">
                  <Share color="primary" />
                  Publicar
                </button>
              ) : (
                <ModalDisabledButton>
                  <Share color="primary" />
                  Publicar
                </ModalDisabledButton>
              )}
            </ModalContent>
          </HotspotModal>
        </Modal>
      )}

      {editModal && (
        <Modal isLarged isOpen={editModal} setIsOpen={toggleModal}>
          <ModalHeader>
            <strong>Edite as informações</strong>
          </ModalHeader>
          <ModalInputArea>
            <Form
              onSubmit={handleUpdateDataProject}
              initialData={initialUpdateFormData}
            >
              <Input name="title" placeholder="Novo título" />
              <TextArea name="description" placeholder="Descrição" />

              <button type="submit">Salvar</button>
              <button
                style={{ backgroundColor: '#f44336' }}
                onClick={() => setEditModal(false)}
                type="button"
              >
                Cancelar
              </button>
            </Form>
          </ModalInputArea>
        </Modal>
      )}

      <Container>
        <Header>
          <strong>{organizationName}</strong>
          <div className="middle">
            <h3>{projectName}</h3>
            <button onClick={() => setEditModal(true)} type="button">
              <ExpandMore />
            </button>
          </div>
          <div>
            <HeaderFileInput>
              <input onChange={handleUpload} type="file" accept="image/*" />
              <AttachFile htmlColor="#CCC" />
            </HeaderFileInput>
            <Link
              target="_blank"
              to={`editor/view/${formattedOrgName}/${code}`}
              onClick={handleSaveToPreview}
            >
              <RemoveRedEye size={18} htmlColor="#CCC" />
            </Link>
            <SaveButton type="button" onClick={handleOpenSaveModal}>
              Salvar
            </SaveButton>
          </div>
        </Header>
        <HorizontalWrap>
          <Sidebar ref={sideRef}>
            {images.map((image, index) => (
              <Preview
                key={image.image}
                index={index}
                label={image.label}
                countImages={images.length}
                source={image.image}
                handleChooseThumbnail={() => handleChooseThumbnail(image.image)}
                handleExcludeImage={() => handleExcludeImage(image.label)}
                onClick={() => setCurrentIndex(index)}
                onChangeLabel={onChangeLabel}
                move={move}
                moveUp={moveUp}
                moveDown={moveDown}
              />
            ))}
          </Sidebar>
          <FloatingContainer>
            {images[currentIndex].missingHotspots.map(missing => (
              <FloatingCard key={missing}>
                <button type="button" onClick={() => scroll(missing)}>
                  Crie um caminho para
                  {` ${missing} `}
                  nessa imagem
                </button>
              </FloatingCard>
            ))}
          </FloatingContainer>
          <View ref={dropRef} id="view">
            <AppProvider>
              <Canvas>
                <Scene
                  images={images}
                  currentIndex={currentIndex}
                  addEvent={addEvent}
                  onClickHotspot={onClickHotspot}
                />
                <Controls />
              </Canvas>
            </AppProvider>
          </View>
        </HorizontalWrap>
      </Container>
    </>
  );
};

export default Editor;
