import React, { useCallback, useState, useMemo, useEffect } from 'react';
import Axios from 'axios';
import pagarme from 'pagarme';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { Link, useParams, useHistory } from 'react-router-dom';
// import { Form } from '@unform/web';
// import { Scope } from '@unform/core';

import data from './utils/data';
import firestore from '../../config/firestore';
import { useAuth } from '../../provider/hooks/auth';

// import Input from '../../components/Input';
import CreditCard from './components/CreditCard';

// import {
//   Container,
//   Header,
//   MenuContainer,
//   Menu,
//   Item,
//   InputLabel,
// } from './styles';

import {
  Container,
  ContentArea,
  Header,
  Input,
  InputLabel,
  Button,
  CancelButton,
  Form,
} from './styles';

const Payment = () => {
  const { organization, updateOrg, user } = useAuth();
  const { plan } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState(0);
  const [name, setName] = useState(user.name || '');
  const [cpf, setCpf] = useState('');
  const [cep, setCep] = useState('');
  const [orgName, setOrgName] = useState(organization.data.name || '');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');

  const [holderName, setHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardCvv, setCardCvv] = useState('');

  const pageTitle = useMemo(() => {
    if (currentForm === 0) {
      return 'Antes de tudo, confirme suas informações';
    }

    if (currentForm === 1) {
      return 'Insira ou modifique os dados do seu endereço';
    }

    return 'Por último, confirme as informações de pagamento';
  }, [currentForm]);

  const formatCpf = useCallback(text => {
    let data = text;

    data = data
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    setCpf(data);
  }, []);

  const getLocationInfo = useCallback(async e => {
    if (e.target.value.length >= 7) {
      const cepService = await Axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json/`
      );

      if (cepService.data) {
        const { logradouro, bairro, localidade, uf } = cepService.data;

        setStreet(logradouro);
        setNeighborhood(bairro);
        setCity(localidade);
        setState(uf);
      }
    }
  }, []);

  const handleChangeForm = useCallback(
    e => {
      e.preventDefault();
      setCurrentForm(currentForm + 1);
    },
    [currentForm]
  );

  // const handleSubmit = useCallback(
  //   async e => {
  //     try {
  //       e.preventDefault();
  //       setLoading(true);
  //       const { card } = formData;

  //       delete formData.card;

  //       // gerar um hash do cartao
  //       const client = await pagarme.client.connect({
  //         encryption_key: process.env.REACT_APP_PAGARME_SECRET_API_KEY,
  //       });

  //       const card_hash = await client.security.encrypt(card);

  //       const response = await Axios.post(
  //         'http://localhost:5001/home-360/us-central1/payment/checkouts',
  //         {
  //           ...formData,
  //           card_hash,
  //           amount: plan === 'starter' ? 49.9 : 100.0,
  //           installments: 1,
  //           items: plan === 'starter' ? starter : pro,
  //         }
  //       );

  //       if (response.data.status === 'paid') {
  //         firestore
  //           .collection('organizacoes')
  //           .where('data.name', '==', organization.data.name)
  //           .get()
  //           .then(result => {
  //             result.docs.forEach(async doc => {
  //               const orgRef = firestore.collection('organizacoes').doc(doc.id);
  //               await firestore
  //                 .collection('transactions')
  //                 .add({ ...response.data, organization_id: doc.id });

  //               orgRef.set({ plan }, { merge: true });
  //               updateOrg({ ...doc.data(), plan });
  //             });
  //           });
  //       } else {
  //         throw new Error('Transaction has been failed');
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [plan]
  // );

  return (
    <Container>
      <Header>
        <strong>{pageTitle}</strong>
      </Header>
      <ContentArea>
        <Form initialData={data} autoComplete="off">
          {currentForm === 0 && (
            <>
              <InputLabel>Nome</InputLabel>
              <Input
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Seu nome completo"
                readOnly
              />

              <InputLabel>CPF</InputLabel>
              <Input
                name="cpf"
                placeholder="Seu CPF"
                value={cpf}
                onChange={e => formatCpf(e.target.value)}
              />

              <InputLabel>CEP</InputLabel>
              <Input
                name="cep"
                placeholder="Seu CEP"
                value={cep}
                onChange={e => {
                  setCep(e.target.value);
                  getLocationInfo(e);
                }}
              />

              <InputLabel>Organização</InputLabel>
              <Input
                name="organization"
                placeholder="Sua organização"
                value={orgName}
                onChange={e => setOrgName(e.target.value)}
                readOnly
              />

              <div className="buttons">
                {cep && cpf ? (
                  <Button onClick={handleChangeForm}>Próximo</Button>
                ) : null}
                <CancelButton
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/dashboard');
                  }}
                >
                  Cancelar
                </CancelButton>
              </div>
            </>
          )}
          {currentForm === 1 && (
            <>
              <InputLabel>Cidade</InputLabel>
              <Input
                name="city"
                value={city}
                onChange={e => setCity(e.target.value)}
                placeholder="Cidade"
              />

              <InputLabel>Estado</InputLabel>
              <Input
                name="state"
                placeholder="Estado"
                value={state}
                onChange={e => setState(e.target.value)}
              />

              <InputLabel>Rua</InputLabel>
              <Input
                name="street"
                placeholder="Rua"
                value={street}
                onChange={e => setStreet(e.target.value)}
              />

              <InputLabel>Bairro</InputLabel>
              <Input
                name="neighborhood"
                placeholder="Bairro"
                value={neighborhood}
                onChange={e => setNeighborhood(e.target.value)}
              />

              <InputLabel>Número</InputLabel>
              <Input
                name="number"
                placeholder="Número"
                value={number}
                onChange={e => setNumber(e.target.value)}
              />

              <div className="buttons">
                {city && state && neighborhood && street && number ? (
                  <Button onClick={handleChangeForm}>Próximo</Button>
                ) : null}
                <CancelButton
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    setCurrentForm(0);
                  }}
                >
                  Voltar
                </CancelButton>
              </div>
            </>
          )}
          {currentForm === 2 && (
            <>
              <div className="methods">
                <button
                  id="card"
                  type="button"
                  className="active"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById('boleto')
                      .classList.remove('active');

                    document.getElementById('card').classList.add('active');
                    setPaymentMethod('card');
                  }}
                >
                  Cartao de Crédito
                </button>
                <button
                  id="boleto"
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    document.getElementById('boleto').classList.add('active');
                    document.getElementById('card').classList.remove('active');
                    setPaymentMethod('boleto');
                  }}
                >
                  Boleto
                </button>
              </div>
              {paymentMethod === 'card' ? (
                <div className="payment">
                  <div>
                    <InputLabel htmlFor="holder">Nome completo</InputLabel>
                    <Input
                      id="holder"
                      placeholder="Como no cartão"
                      value={holderName}
                      onChange={e => setHolderName(e.target.value)}
                    />

                    <InputLabel htmlFor="number">Número</InputLabel>
                    <Input
                      id="number"
                      placeholder="Número do cartão"
                      value={cardNumber}
                      onChange={e => setCardNumber(e.target.value)}
                    />

                    <InputLabel htmlFor="date">Data de expiração</InputLabel>
                    <Input
                      id="date"
                      placeholder="Data de expiração do cartão"
                      value={cardExpirationDate}
                      onChange={e => setCardExpirationDate(e.target.value)}
                    />

                    <InputLabel htmlFor="cvv">Código de segurança</InputLabel>
                    <Input
                      id="cvv"
                      placeholder="CVV"
                      value={cardCvv}
                      onChange={e => setCardCvv(e.target.value)}
                    />
                  </div>
                  <CreditCard
                    name={holderName}
                    cvv={cardCvv}
                    expiry={cardExpirationDate}
                    number={cardNumber}
                  />
                </div>
              ) : (
                  <h1>Esse meio de pagamento ainda nao está disponivel</h1>
                )}
              <div className="buttons">
                {holderName && cardNumber && cardExpirationDate && cardCvv ? (
                  <Button onClick={() => { }}>Finalizar</Button>
                ) : null}
                <CancelButton
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    setCurrentForm(1);
                  }}
                >
                  Voltar
                </CancelButton>
              </div>
            </>
          )}
        </Form>
      </ContentArea>
    </Container>
  );
};

export default Payment;
