import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 600px;
  margin: 0 auto;

  margin-top: 180px;
`;

export const InputLabel = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.text.primary};
  font-weight: bold;

  margin-bottom: 8px;
  margin-top: 10px;
`;

export const Button = styled.button`
  height: 50px;
  color: #fff;
  background: ${({ theme }) => theme.colors.secondary};
  border: 0 !important;
  font-size: 22px;
  font-weight: bold;
  width: 100%;

  margin-top: 20px;
  border-radius: 8px;
`;
