import React, { useEffect, useState } from 'react';
import { Container } from './styles';

export default function Modal({ children, isOpen, setIsOpen, isLoading }) {
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <Container
      shouldCloseOnOverlayClick={!false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '59%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: isLoading ? 'transparent' : '#fff',
          color: '#000',
          borderRadius: '8px',
          maxWidth: '450px',
          border: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      }}
    >
      {children}
    </Container>
  );
}
