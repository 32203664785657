import bcrypt from 'bcryptjs';
import authConfig from '../auth/config';
import database from '../config/firestore';
import { storageUser } from '../constants/storage';

export default class Firebase {
  constructor() {
    this.owner = JSON.parse(localStorage.getItem(storageUser)) || '';
  }

  async authenticateUser(email, password) {
    const response = await authConfig
      .auth()
      .signInWithEmailAndPassword(email, password);

    const { user } = response;

    return user;
  }

  async createUserWithEmailAndPassword(name, email, password) {
    try {
      const response = await authConfig
        .auth()
        .createUserWithEmailAndPassword(email, password);

      const { uid } = response.user;
      const hash = await bcrypt.hash(password, 10);

      const user = await this.store('usuarios', {
        id: uid,
        organization: [],
        name,
        email,
        profilePhoto: null,
        password: hash,
      });

      return user;
    } catch (err) {
      return err;
    }
  }

  async updatePassword(email) {
    await authConfig.auth().sendPasswordResetEmail(email);
  }

  async store(collection, data) {
    const response = await database.collection(collection).add(data);

    return response;
  }

  async getUserById(collection, id) {
    const response = await database
      .collection(collection)
      .where('id', '==', id)
      .get();

    let currentUser;
    response.docs.forEach(user => {
      currentUser = user.data();
    });

    return currentUser;
  }

  async loadProjects(collection, ref) {
    const response = await database
      .collection(collection)
      .where('organization', '==', ref)
      .where('isInTrash', '==', false)
      .get();

    const projects = response.docs.map(project => {
      return { ...project.data(), id: project.id };
    });

    return projects;
  }

  async loadPublishedProjects(collection, ref) {
    const response = await database
      .collection(collection)
      .where('organization', '==', ref)
      .where('publicado', '==', true)
      .where('isInTrash', '==', false)
      .get();

    const projects = response.docs.map(project => {
      return { ...project.data(), id: project.id };
    });

    return projects;
  }

  async loadProjectsInTrash(collection, ref) {
    const response = await database
      .collection(collection)
      .where('organization', '==', ref)
      .where('isInTrash', '==', true)
      .get();

    const projects = response.docs.map(project => {
      return { ...project.data(), id: project.id };
    });

    return projects;
  }

  async deleteProject(collection, ref) {
    await database.collection(collection).doc(ref).delete();
  }

  async loadCorrectors(collection, ref) {
    const response = await database
      .collection(collection)
      .where('name', '==', ref)
      .get();

    const correctors = response.docs.map(corrector => {
      return { ...corrector.data() };
    });

    return correctors;
  }

  async update(collection, ref, data) {
    const updateRef = database.collection(collection).doc(ref);

    updateRef.set(
      {
        data,
      },
      { merge: true }
    );
  }

  async registerOrganization(data) {
    const currentUser = await database
      .collection('usuarios')
      .where('id', '==', this.owner.id)
      .get();

    const createdOrg = await this.store('organizacoes', data);

    currentUser.docs.forEach(user => {
      const currentUserRef = database.collection('usuarios').doc(user.id);

      currentUserRef.set(
        {
          organization: [
            ...user.data().organization,
            { name: data.data.name, category: data.category },
          ],
        },
        { merge: true }
      );

      createdOrg.set(
        {
          users: [
            {
              name: user.data().name,
              email: user.data().email,
              profilePhoto: user.data().profilePhoto,
              role: 'Proprietário',
            },
          ],
        },
        { merge: true }
      );
    });

    const organization = await createdOrg.get();

    return organization.data();
  }

  async loadOrgsAssociated() {
    const response = await database
      .collection('organizacoes')
      .where('owner', '==', this.owner.id)
      .get();

    let currentOrg;

    response.docs.forEach(doc => {
      currentOrg = doc.data();
    });

    return currentOrg;
  }

  async getProjectImages(ref) {
    const response = await database
      .collection('tours360')
      .where('projectCode', '==', ref)
      .get();

    const projects = response.docs.map(project => {
      return { ...project.data(), id: project.id };
    });

    return projects;
  }

  async search(filter, organization) {
    const response = await database
      .collection('tours360')
      .where('organization', '==', organization)
      .get();

    const projects = response.docs.map(project => {
      return { ...project.data(), id: project.id };
    });

    const searchFilter = filter.toLowerCase();

    const filteredProjects = projects.filter(project => {
      if (project.corretor === searchFilter) {
        return project;
      }

      if (project.description.includes(searchFilter)) {
        return project;
      }

      if (project.name.includes(searchFilter)) {
        return project;
      }

      if (project.projectCode === searchFilter) {
        return project;
      }
    });

    return filteredProjects;
  }

  async userExists(organization, user) {
    const response = await database
      .collection('organizacoes')
      .where('data.name', '==', organization)
      .get();

    let exists = false;
    response.docs.forEach(organization => {
      const { users } = organization.data();

      const verify = users.filter(current => current.email === user);

      if (verify.length) exists = true;
    });

    return exists;
  }

  async userInvited(organization, user) {
    const response = await database
      .collection('notifications')
      .where('organization', '==', organization)
      .where('destiny', '==', user)
      .get();

    let alreadyInvited = false;

    response.docs.forEach(doc => {
      if (doc.exists) {
        alreadyInvited = true;
      }
    });

    return alreadyInvited;
  }

  async deleteUser(organization, ident) {
    const response = await database
      .collection('organizacoes')
      .where('data.name', '==', organization)
      .get();

    let currentUsers = [];
    response.docs.forEach(organization => {
      const { users } = organization.data();

      const filteredUsers = users.filter(user => user.email !== ident);

      currentUsers = filteredUsers;

      const orgRef = database.collection('organizacoes').doc(organization.id);

      orgRef.set(
        {
          users: currentUsers,
        },
        { merge: true }
      );

      database
        .collection('usuarios')
        .get()
        .then(response => {
          response.docs.forEach(user => {
            const { organization: orgs } = user.data();

            const filteredOrganizations = orgs.filter(
              org => org.name !== organization
            );

            database.collection('usuarios').doc(user.id).set(
              {
                organization: filteredOrganizations,
              },
              { merge: true }
            );
          });
        });
    });

    return currentUsers;
  }

  async acceptInvite(destiny) {
    const response = await database
      .collection('notifications')
      .where('destiny', '==', destiny)
      .get();

    response.docs.forEach(doc => {
      const notificationsRef = database.collection('notifications').doc(doc.id);

      notificationsRef.set(
        {
          ...doc.data(),
          status: 'Aceito',
        },
        { merge: true }
      );
    });
  }

  async dimissInvite(destiny) {
    const response = await database
      .collection('notifications')
      .where('destiny', '==', destiny)
      .get();

    response.docs.forEach(doc => {
      console.log(doc.data());
      const notificationsRef = database.collection('notifications').doc(doc.id);

      notificationsRef.set(
        {
          ...doc.data(),
          status: 'Recusado',
        },
        { merge: true }
      );
    });
  }

  async expiresInvite(id) {
    setTimeout(() => {
      database.collection('notifications').doc(id).set(
        {
          status: 'Expirado',
        },
        { merge: true }
      );
    }, 86400000);
  }
}
