import React from 'react';
import AuthProvider from './hooks/auth';
import EventProvider from './hooks/events';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <EventProvider>{children}</EventProvider>
  </AuthProvider>
);

export default AppProvider;
