import styled, { css } from 'styled-components';

export const Container = styled.ul`
  margin-top: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Input = styled.input`
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.text.secondary};
  background: #fff;
  padding: 0 12px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);

  ${props =>
    props.isFilled &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `};

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const Preview = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
  }

  button {
    background: none;
    color: red;
    border: none;
    margin-left: 10px;
    margin-top: 5px;
  }
`;
