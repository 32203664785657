export default {
  background: '#fff',

  colors: {
    primary: '#2C348C',
    secondary: '#ff3d00',
  },

  text: {
    primary: '#312e38',
    secondary: '#7C758D',
  },
};
