import React from 'react';
import { Route } from 'react-router-dom';
import Loading from '../components/Loading';
import { useAuth } from '../provider/hooks/auth';

const Invite = ({ component: Component, ...rest }) => {
  const { loading } = useAuth();

  if (loading) return <Loading />;
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default Invite;
