import styled, { css } from 'styled-components';
import { ShareButton } from 'react-facebook-sdk';
import noThumb from '../../assets/no-thumb.jpg';

const buttonTypeVariations = {
  copy: css`
    background-color: ${({ theme }) => theme.colors.secondary};
    svg {
      color: #fff;
    }
  `,
  delete: css`
    background-color: #f44436;
    svg {
      color: #fff;
    }
  `,
  edit: css`
    background-color: #5952ff;
    svg {
      color: #fff;
    }
  `,

  restore: css`
    background-color: #6ac87e;
    svg {
      color: #fff;
    }
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;

  position: relative;

  padding: 0 0 10px;

  width: 100%;
  max-width: 450px;

  height: 100%;
  max-height: 280px;
  min-height: 280px;

  background: #f7f7f7;
  border-radius: 4px;

  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;

export const Views = styled.div`
  position: absolute;

  left: 0%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 8px rgba(255, 255, 255, 0.3);
  height: 40px;
  min-width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 10px;

  border-radius: 4px;

  color: ${({ theme }) => theme.colors.secondary};

  svg {
    margin-right: 5px;

    color: inherit;
  }
`;

export const Header = styled.header`
  height: 110px;
  min-height: 110px;
  border-bottom: 1px solid #ddd;

  background: url(${props => props.background || noThumb}) no-repeat center;
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 8px;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 500;
      color: #444;
    }
  }

  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      text-transform: capitalize;
      font-weight: 12px;
      min-height: 50px;
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px 0;

  span {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
  }
`;

export const Controls = styled.div`
  display: flex;
`;

export const Button = styled.button`
  ${props => buttonTypeVariations[props.type]};

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;

  border: none;
  border-radius: 20%;

  margin-right: 5px;

  svg {
    height: 20px;
  }
`;

export const Code = styled.div`
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  background: rgba(44, 52, 140, 0.8);
  box-shadow: 2px 2px 8px rgba(44, 52, 140, 0.3);
  height: 40px;
  min-width: 110px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 10px;

  border-radius: 4px;

  strong {
    color: #fff;
    text-transform: uppercase;
  }
`;

export const FacebookShare = styled(ShareButton)`
  background: none;
  border: none;
  margin-right: 5px;
  background: #3f51b5;

  height: 30px;
  width: 30px;

  border-radius: 20%;

  svg {
    color: #fff;
    height: 20px;
  }
`;
