import styled, { keyframes } from 'styled-components';
import BackgroundImg from '../../assets/background-forms.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromRight} 1.2s;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 700px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;

    h1 {
      font-size: 32px;
      color: #282828;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      margin-bottom: 20px;
      color: #2d2c2c;
    }

    > button {
      width: 100%;
      max-width: 580px;
      height: 52px;
      border-radius: 6px;
      border: none;
      background: ${({ theme }) => theme.colors.primary};
      margin: 10px 0;
      box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.2);

      color: #fff;
      font-size: 20px;
      font-weight: bold;

      transition: all 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    span {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;
      color: #444;
    }

    a {
      margin-top: 5px;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 18px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${BackgroundImg}) no-repeat center;
  background-size: cover;
`;
