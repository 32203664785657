import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useField } from '@unform/core';

import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';

import { Container, Error } from './styles';

const Input = ({
  name,
  isPassword,
  multiple,
  icon: Icon = false,
  ...props
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const { defaultValue = '', error, fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current.value);
  }, []);

  const handleMakePasswordVisible = useCallback(() => {
    inputRef.current.type = 'text';

    setIsVisible(true);
  }, []);

  const handleMakePasswordNotVisible = useCallback(() => {
    inputRef.current.type = 'password';

    setIsVisible(false);
  }, []);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      {Icon && <Icon htmlColor="#55EEBB" />}
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        multiple={multiple}
        {...props}
      />
      {isPassword &&
        (isVisible ? (
          <button type="button" onClick={handleMakePasswordNotVisible}>
            <VisibilityOff />
          </button>
        ) : (
          <button type="button" onClick={handleMakePasswordVisible}>
            <Visibility />
          </button>
        ))}
      {error && (
        <Error title={error}>
          <ErrorOutline htmlColor="#fc8077" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
