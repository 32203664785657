import React, { useState, useCallback } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError } from 'react-icons/md';

import 'react-circular-progressbar/dist/styles.css';

import { Container, FileInfo, Preview, Actions, Input } from './styles';

const FileList = ({ files, handleDelete }) => {
  const [isFilled, setIsFilled] = useState(true);

  const handleInputBlur = useCallback(() => {
    files.forEach(file => {
      setIsFilled(!!file.readableName);
    });
  }, [files]);

  return (
    <Container>
      {files.map(uploadedFile => (
        <li key={uploadedFile.id}>
          <FileInfo>
            <Preview src={uploadedFile.preview} />
            <div>
              <Input
                onBlur={handleInputBlur}
                isFilled={isFilled}
                type="text"
                placeholder="Nome do ambiente"
                defaultValue={uploadedFile.readableName}
                onChange={e => {
                  uploadedFile.readableName = e.target.value;
                }}
              />
              {uploadedFile.uploaded && (
                <Actions>
                  <span>{uploadedFile.readableSize}</span>
                  <button
                    onClick={() =>
                      handleDelete(
                        uploadedFile.id,
                        uploadedFile.name,
                        uploadedFile.url
                      )
                    }
                    type="button"
                  >
                    Excluir
                  </button>
                </Actions>
              )}
            </div>
          </FileInfo>

          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#007CFC' },
                }}
                strokeWidth={10}
                value={uploadedFile.progress}
              />
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color="#78e5d5" />
            )}
            {uploadedFile.error && <MdError size={24} color="#e57878" />}
          </div>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
