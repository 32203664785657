/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  background: linear-gradient(#007cfc 65%, #fff 35%);
  padding: 0 50px;

  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  div {
    animation: ${appearFromRight} 1.2s;
    margin-top: 70px;
    width: 550px;
    display: flex;
    flex-direction: column;

    strong {
      font-size: 42px;
      color: #fff;
      font-weight: bold;
    }
    p {
      margin: 20px 0;
      text-align: justify;
      font-size: 22px;
      display: block;
      color: #fff;
    }
    span {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 400px;
      height: 50px;
      background-color: #ff9000;
      color: #fff;
      border-radius: 8px;
      margin-top: 45px;
      font-size: 18px;
      font-weight: bold;
      transition: all 0.3s;

      &:hover {
        background: #fff;
        color: #ff9000;
        border: 3px solid #ff9000;
      }
    }
  }
`;
