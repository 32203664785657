/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const appearFromBottom = keyframes`
  0% {
    transform: translateY(120px);
  }
  50% {
    transform: translateY(-20px);
    transform: scale(1.05);
  }
  100% {
    transform: translateY(0);
    transform: scale(1);
  }
`;

export const Container = styled.div`
  height: 100vh;
  background: linear-gradient(#007cfc 65%, #fff 35%);
  padding: 0 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    animation: ${appearFromBottom} 1s;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-right: 20px;
    border-radius: 10px;
    height: 200px;
    width: 200px;
    background-color: #eee;
    border: none;
    transition: all 0.3s;

    img {
      height: 100px;
    }

    strong {
      font-size: 22px;
      color: #ff9000;
      margin-top: 20px;
    }

    &:hover {
      transform: scale(1.1);
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
    }
  }
`;
