import React, { useCallback } from 'react';
import {
  Home,
  Build,
  Create,
  SquareFoot,
  WbSunny,
  Hotel,
  Storefront,
  Mood,
  MoreHoriz,
} from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { Container, Content, Cards } from './styles';
import { setCategoryBusiness } from '../../../utils/storage';

const OrgCategory = () => {
  const categories = [
    { id: 1, name: 'Imobiliaria' },
    { id: 2, name: 'Construtora' },
    { id: 3, name: 'Arquitetura' },
    { id: 4, name: 'Engenharia' },
    { id: 5, name: 'Móveis planejados' },
    { id: 6, name: 'Turismo & Hotelaria' },
    { id: 7, name: 'Design de interiores' },
    { id: 8, name: 'Marketing' },
    { id: 9, name: 'Outros' },
  ];

  const categoriesIcon = [
    <Home size={45} />,
    <Build size={45} />,
    <Create size={45} />,
    <SquareFoot size={45} />,
    <WbSunny size={45} />,
    <Hotel size={45} />,
    <Storefront size={45} />,
    <Mood size={45} />,
    <MoreHoriz size={45} />,
  ];

  const history = useHistory();

  const handleChooseCategory = useCallback(
    category => {
      setCategoryBusiness(category);
      history.push('/last-step');
    },
    [history]
  );

  return (
    <Container>
      <Content>
        <strong>Selecione a categoria da sua organização</strong>
        <Cards>
          {categories.map((category, index) => (
            <button
              type="button"
              onClick={() => handleChooseCategory(category.name)}
              key={category.id}
            >
              {categoriesIcon[index]}
              {category.name}
            </button>
          ))}
        </Cards>
      </Content>
    </Container>
  );
};

export default OrgCategory;
