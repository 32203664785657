import { makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Avatar } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
`;

export const User = styled.button`
  width: 50px;
  height: 50px;

  border-radius: 50%;
  background: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    height: 100%;
    flex: 1;
    flex-shrink: 0;
  }
`;

export const UserAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.colors.secondary} !important;
`;

export const MenuUser = styled.div`
  position: absolute;
  top: 80px;
  right: 20px;

  width: 100%;
  max-width: 220px;

  background: #fff;
  box-shadow: 2px 2px 5px #ddd;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 8px;

  svg {
    margin-left: 20px;
  }

  &::before {
    content: '';
    border-style: solid;
    border-color: #f2f2f2 transparent;
    border-width: 0 12px 12px 12px;
    top: -5%;
    position: absolute;
    left: 83%;
    transform: translateX(-50%);
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;

  padding: 20px 0;

  div {
    margin-bottom: 8px;
    height: 50px;
    width: 50px;
  }
`;

export const MenuUserItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;

  background: none;
  border: 0;

  color: #444;

  span {
    font-size: 17px;
    font-weight: 400;
  }

  svg {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    background: ${shade(0.1, '#fff')};
  }
`;

export const AreaHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 30px 0;

  img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }

  strong {
    max-width: 200px;
    font-size: 18px;
    color: #fff;
    margin-top: 8px;
    text-align: center;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 600,
  },
  page: {
    fontSize: 22,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: '#fff',
    boxShadow: '2px 2px 5px #ddd',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#2C348C',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icon: {
    color: '#f3f3f3',
  },
  title: {
    color: '#f3f3f3',
  },
}));

export { useStyles };
