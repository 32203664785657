import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Backspace, Add } from '@material-ui/icons';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { useAuth } from '../../provider/hooks/auth';
import {
  Container,
  SearchProjects,
  ProjectHeader,
  Content,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from './styles';
import Card from '../../components/Card';
import Aside from '../../components/Aside';
import Firebase from '../../services/Firebase';
import Modal from './components/Modal';
import {
  storageImagesURL,
  storageProjectTitle,
  storageProject,
  storageProjectCode,
} from '../../constants/storage';
import firestore from '../../config/firestore';

const api = new Firebase();
const animated = makeAnimated();

const options = [
  { label: 'Todos os projetos', value: 'all' },
  { label: 'Na lixeira', value: 'isInTrash' },
  { label: 'Publicados', value: 'isPublished' },
];

const Dashboard = () => {
  const inputRef = useRef(null);
  const { user, organization } = useAuth();
  const history = useHistory();

  const [projects, setProjects] = useState([]);
  const [ownerOptions] = useState([
    { label: 'Todos os projetos', value: 'all' },
    { label: 'Na lixeira', value: 'isInTrash' },
    { label: 'Publicados', value: 'isPublished' },
  ]);

  const [userOptions] = useState([
    { label: 'Todos os projetos', value: 'all' },
    { label: 'Publicados', value: 'isPublished' },
  ]);

  const [isProjectNotFound, setIsProjectNotFound] = useState(false);

  const [time, setTime] = useState(null);

  const [projectFilter, setProjectFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);

  async function loadProjects() {
    if (projectFilter === 'all') {
      const allProjectsInDatabase = await api.loadProjects(
        'tours360',
        organization.data.name
      );

      setProjects(allProjectsInDatabase);
    } else if (projectFilter === 'isPublished') {
      const publishedProjectsInDatabase = await api.loadPublishedProjects(
        'tours360',
        organization.data.name
      );

      setProjects(publishedProjectsInDatabase);
    } else {
      const trashedProjectsInDatabase = await api.loadProjectsInTrash(
        'tours360',
        organization.data.name
      );

      setProjects(trashedProjectsInDatabase);
    }
  }

  useEffect(() => {
    loadProjects();
  }, [projectFilter]);

  const formattedProject = useMemo(
    () =>
      projects.map(project => ({
        ...project,
        name:
          project.name.length > 40
            ? project.name.substr(0, 40).concat('...')
            : project.name,
        description:
          project.description.length > 60
            ? project.description.substr(0, 60).concat('...')
            : project.description,
      })),
    [projects]
  );

  const handleDelete = useCallback(
    async id => {
      await firestore.collection('tours360').doc(id).delete();

      const updatedProjects = projects.filter(project => project.id !== id);

      setProjects(updatedProjects);
      setShowModal(false);

      toast.info('Projeto excluido');
    },
    [projects]
  );

  const handleMoveToTrash = useCallback(
    async id => {
      if (projectFilter === 'all' || projectFilter === 'isPublished') {
        const projectReference = firestore.collection('tours360').doc(id);

        projectReference.get().then(response => {
          if (response.data().isInTrash) {
            toast.error('O projeto selecionado já está na lixeira');

            return;
          }

          projectReference.set(
            {
              isInTrash: true,
            },
            { merge: true }
          );
          loadProjects();
          toast.info('Projeto movido para lixeira');
        });
      }
    },
    [projectFilter, projects, loadProjects]
  );

  const handleRestore = useCallback(
    id => {
      firestore.collection('tours360').doc(id).set(
        {
          isInTrash: false,
        },
        { merge: true }
      );

      loadProjects();
    },
    [loadProjects]
  );

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleEdit = useCallback(
    async ref => {
      const projectImages = await api.getProjectImages(ref);

      let projectImagesWithUrlAndLinks;

      projectImages.forEach(project => {
        projectImagesWithUrlAndLinks = JSON.parse(project.images);

        localStorage.setItem(storageProject, project.id);
        localStorage.setItem(storageProjectTitle, project.name);
        localStorage.setItem(storageProjectCode, project.projectCode);
      });

      const formattedProjects = projectImagesWithUrlAndLinks.map(project => {
        return {
          url: project.image,
          label: project.label,
          links: project.links,
        };
      });

      localStorage.setItem(storageImagesURL, JSON.stringify(formattedProjects));
      history.push('/editor');
    },
    [history]
  );

  const navigate = useCallback(() => {
    history.push('/project-info');
  }, [history]);

  const searchForProject = useCallback(
    async e => {
      e.persist();

      clearTimeout(time);

      const filter = e.target.value;
      if (filter === '') {
        const unfilteredProjects = await api.loadProjects(
          'tours360',
          organization.data.name
        );

        setProjects(unfilteredProjects);
        setIsProjectNotFound(false);
        return;
      }

      const timeout = setTimeout(async () => {
        const filteredProjects = await api.search(
          filter,
          organization.data.name
        );

        if (filteredProjects.length > 0) {
          setProjects(filteredProjects);
          setIsProjectNotFound(false);
        } else {
          setIsProjectNotFound(true);
        }
      }, 700);

      setTime(timeout);
    },
    [time, organization.data.name]
  );

  const handleClearInput = useCallback(async () => {
    inputRef.current.value = '';
    setIsProjectNotFound(false);
    const unfilteredProjects = await api.loadProjects(
      'tours360',
      organization.data.name
    );

    setProjects(unfilteredProjects);
  }, [organization.data.name]);

  return (
    <Aside page="Tour Virtual">
      <Container>
        <SearchProjects>
          <input
            type="text"
            placeholder="Buscar projeto"
            onChange={searchForProject}
            ref={inputRef}
          />
          <button type="button" onClick={handleClearInput}>
            <Backspace />
          </button>
        </SearchProjects>

        <ProjectHeader>
          <ReactSelect
            onChange={e => setProjectFilter(e.value)}
            closeMenuOnSelect
            components={animated}
            defaultValue={options[0]}
            options={
              user.id === organization.owner ? ownerOptions : userOptions
            }
          />
          <div>
            <button onClick={navigate} type="button">
              Novo Projeto
              <Add />
            </button>
          </div>
        </ProjectHeader>

        <Content>
          {!isProjectNotFound ? (
            formattedProject.map(project => (
              <>
                <Modal isOpen={showModal} setIsOpen={toggleModal}>
                  <ModalContent>
                    <ModalHeader>
                      <h3>Tem certeza que seja excluir o projeto?</h3>
                    </ModalHeader>
                    <ModalBody>
                      <p>
                        A ação não poderá ser desfeita e o projeto será excluído
                        permanentemente
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <ModalFooter>
                        <Button
                          onClick={() => setShowModal(false)}
                          cancel
                          type="button"
                        >
                          Cancelar
                        </Button>
                        <Button
                          onClick={() => handleDelete(project.id)}
                          type="button"
                        >
                          Excluir
                        </Button>
                      </ModalFooter>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Card
                  key={project.id}
                  code={project.projectCode}
                  thumbnail={project.thumbNail}
                  title={project.name}
                  description={project.description}
                  responsable={project.corretor}
                  isPublic={project.publicado}
                  views={project.viewsCount}
                  isInTrash={project.isInTrash}
                  id={project.id}
                  canPublish={project.canPublish}
                  onEdit={() => handleEdit(project.projectCode)}
                  onDelete={() => setShowModal(true)}
                  onMoveFromTrash={() => handleMoveToTrash(project.id)}
                  onRestore={() => handleRestore(project.id)}
                />
              </>
            ))
          ) : (
            <h1>Nenhum tour encontrado :(</h1>
          )}
        </Content>
      </Container>
    </Aside>
  );
};

export default Dashboard;
