/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useCallback } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { useParams } from 'react-router-dom';

import { Canvas } from 'react-three-fiber';
import {
  ExpandMore,
  PlayCircleFilled,
  PauseCircleFilled,
  InfoSharp,
  LocationOn,
  Menu as MenuIcon,
  Home,
  MyLocation,
} from '@material-ui/icons';

import Axios from 'axios';
import firestore from '../../config/firestore';
import { useEvent } from '../../provider/hooks/events';

import Controls from './Controls';
import Scene from './Scene';
import Modal from './components/Modal';

import noThumb from '../../assets/no-thumb.jpg';

import {
  Container,
  Preview,
  ArrowButton,
  Organization,
  PreviewCarousel,
  PerformCarousel,
  BottomContainer,
  Hotspot,
  Menu,
  LoadingContainter,
  Loading,
  ModalContent,
  Play,
  Infos,
  Location,
  Bottom,
  Description,
} from './styles';

const TOKEN = 'da201632624ae3';

const View = () => {
  const { projectCode } = useParams();

  const [isShown, setIsShown] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showTourInfo, setShowTourInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [started, setStarted] = useState(false);

  const [logoOrg, setLogoOrg] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  const [location, setLocation] = useState([{}]);

  const [, setCurrentPosition] = useState([0, 0]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [images, setImages] = useState([]);

  const { isOverIndex, setOverIndex } = useEvent();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(response => {
      const { latitude: lat, longitude: long } = response.coords;

      setCurrentPosition([lat, long]);
    });
  }, []);

  useEffect(() => {
    async function getImages() {
      try {
        const response = await firestore
          .collection('tours360')
          .where('projectCode', '==', projectCode)
          .get();

        response.docs.forEach(async project => {
          const myImages = JSON.parse(project.data().images);
          const imageLabel = myImages[currentIndex].label;
          setOverIndex(imageLabel);

          setIsPublished(project.data().publicado);
          setLogoOrg(project.data().organizationLogo);
          setImages(JSON.parse(project.data().images));
          setThumbnail(project.data().thumbNail);
          setProjectName(project.data().name);
          setProjectDescription(project.data().description);

          const cepService = await Axios.get(
            `https://viacep.com.br/ws/${project.data().cep}/json/`
          );

          setLocation(cepService.data);

          const geoLocationService = await Axios.get(
            `https://us1.locationiq.com/v1/search.php?key=${TOKEN}&q=${cepService.data.logradouro}&format=json`
          );

          const { lat, lon } = geoLocationService.data[0];

          setLatitude(lat);
          setLongitude(lon);

          const tourReference = firestore
            .collection('tours360')
            .doc(project.id);

          tourReference.get().then(result => {
            tourReference.set(
              {
                viewsCount: result.data().viewsCount + 1,
              },
              { merge: true }
            );
          });
        });

        setIsLoading(false);
      } catch (err) {
        setHasError(true);
        window.location.reload();
      }
    }
    getImages();
  }, [projectCode, currentIndex]);

  useEffect(() => {
    setIsLoading(true);
  }, [currentIndex]);

  useEffect(() => {
    if (started) {
      setTimeout(() => {
        if (currentIndex < images.length - 1) {
          setCurrentIndex(currentIndex + 1);
        }
      }, 25000);
    }
  }, [currentIndex, started]);

  const onClickHotspot = useCallback(to => {
    setCurrentIndex(to);
  }, []);

  const handleNavigateFromPreview = useCallback(index => {
    setIsShown(false);
    setCurrentIndex(index);
  }, []);

  const hideBottomContainer = useCallback(() => {
    setIsShown(false);
    setShowMenu(false);
  }, [showMenu]);

  const showBottomContainer = useCallback(() => {
    setShowMenu(true);
  }, [showMenu]);

  const toggleModal = useCallback(() => {
    setShowTourInfo(!showTourInfo);
  }, [showTourInfo]);


  const handleDispareViewAnimation = useCallback(() => {
    setStarted(!started);
  }, [started]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Container>
        {hasError ? (
          <h3>Occorreu um erro, por favor recarregue a página</h3>
        ) : !isPublished ? (
          <h1>Recurso indisponivel</h1>
        ) : (
              <>
                <div id="view">
                  <Canvas
                    onPointerMove={() => setIsShown(false)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Scene
                      images={images}
                      currentIndex={currentIndex}
                      onClickHotspot={onClickHotspot}
                      dispare={started}
                    />
                    <Controls />
                  </Canvas>
                  {isLoading && (
                    <LoadingContainter>
                      <Loading />
                    </LoadingContainter>
                  )}
                  <Preview isShown={isShown}>
                    <PerformCarousel responsive={responsive}>
                      {images.map((image, index) => (
                        <PreviewCarousel
                          type="button"
                          onClick={() => handleNavigateFromPreview(index)}
                          image={image.image}
                          key={image.label}
                        >
                          <h1>{image.label}</h1>
                        </PreviewCarousel>
                      ))}
                    </PerformCarousel>
                  </Preview>
                  <Organization onClick={() => setIsShown(false)}>
                    <img src={logoOrg} alt="Logo" />
                  </Organization>
                  {!isShown ? (
                    <ArrowButton onClick={() => setIsShown(!isShown)}>
                      <ExpandMore fontSize="large" />
                    </ArrowButton>
                  ) : null}
                </div>
              </>
            )}
      </Container>

      <BottomContainer hide={showMenu}>
        <Hotspot>
          <strong>{isOverIndex}</strong>
        </Hotspot>
        <Menu>
          <Play onClick={handleDispareViewAnimation}>
            {!started ? <PlayCircleFilled /> : <PauseCircleFilled />}
          </Play>
          <Infos onClick={() => setShowTourInfo(true)}>
            <InfoSharp />
          </Infos>
        </Menu>
      </BottomContainer>

      <Modal setIsOpen={toggleModal} isOpen={showTourInfo}>
        <ModalContent>
          <h1>Informações do projeto</h1>
          <div>
            <Map
              animate
              duration={1.5}
              center={[latitude, longitude]}
              zoom={15}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[latitude, longitude]} />
            </Map>
          </div>
          <Bottom>
            <Location>
              <strong>Localização</strong>
              <div>
                <Home />
                <span>{location.logradouro}</span>
              </div>
              <div>
                <LocationOn />
                <span>{`${location.localidade}, ${location.uf}`}</span>
              </div>
              <div>
                <MyLocation />
                <span>{location.bairro}</span>
              </div>
              {/* <div>
                <Explore />
                <a
                  href={`https://www.google.com.br/maps/@${latitude},-${longitude}`}
                >
                  Street View
                </a>
              </div> */}
            </Location>
            <Description>
              <strong>Descrição</strong>
              <div>
                <p>{projectDescription}</p>
              </div>
            </Description>
          </Bottom>
        </ModalContent>
      </Modal>
    </>
  );
};

export default View;
