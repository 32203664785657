import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Avatar } from '@material-ui/core';

export const Container = styled.div`
  min-height: 100vh;
  background: ${({ theme }) =>
    `linear-gradient(${theme.colors.primary} 65%, ${theme.background} 35%)`};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;

  a {
    position: absolute;
    top: 30px;
    left: 30px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s;
    display: flex;
    align-items: center;

    &:hover {
      color: #ddd;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;
    margin-top: 5px;

    background: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

    button[type='submit'] {
      width: 100%;
      height: 42px;
      margin-top: 10px;

      background: ${({ theme }) => theme.colors.secondary};
      border: none;
      border-radius: 6px;
      color: #fdfdfd;
      font-weight: bold;
      font-size: 18px;
      transition: all 0.4s;

      &:hover {
        background: ${({ theme }) => shade(0.3, `${theme.colors.secondary}`)};
        color: ${shade(0.3, '#fff')};
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  color: #fff;
`;

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 5px 15px;
  margin: 20px 0 10px;
  font-size: 16px;
  color: #333;
`;

export const InputLabel = styled.label`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 0;
  color: #333;
`;
export const Users = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;
`;

export const UserCard = styled.div`
  cursor: pointer;
  background-size: cover;

  img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
`;

export const UserAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.colors.secondary} !important;

  margin-right: 12px;
`;
