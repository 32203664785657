import React from 'react';
import ReactLottie from 'react-lottie';

const Animation = ({ source, custom }) => {
  return (
    <ReactLottie
      height={custom ? 400 : 200}
      width={custom ? 400 : 200}
      options={{
        animationData: source,
        autoPlay: true,
        loop: !!custom,
      }}
    />
  );
};

export default Animation;
