import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ChevronLeft } from '@material-ui/icons';
import cepPromise from 'cep-promise';
import { Tooltip } from '@material-ui/core';
import firestore from '../../config/firestore';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../provider/hooks/auth';
import {
  storageProject,
  storageProjectTitle,
  storageProjectCode,
} from '../../constants/storage';

import Input from '../../components/Input';
import TextArea from '../../components/TextArea';

import {
  Container,
  Title,
  InputLabel,
  Users,
  UserCard,
  UserAvatar,
} from './styles';

const ProjectInfo = () => {
  const [users, setUsers] = useState([]);
  const [corrector, setCorrector] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { organization } = useAuth();

  const validateForm = useCallback(async data => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string().required('O nome é obrigatorio'),
      description: Yup.string('Informe uma descrição valida'),
      code: Yup.string().required('Informe um codigo'),
      cep: Yup.string().required('Informe um CEP válido'),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }, []);

  const validateCep = useCallback(async cep => {
    const response = await cepPromise(cep);
    return response;
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        await validateForm(data);
        await validateCep(data.cep);

        setLoading(true);

        const newTour = await firestore.collection('tours360').add({
          name: data.name.toLowerCase(),
          description: data.description.toLowerCase(),
          cep: data.cep,
          projectCode: data.code.toLowerCase(),
          corretor: corrector.toLocaleLowerCase(),
          publicado: false,
          organization: organization.data.name,
          organizationLogo: organization.logoPhoto || null,
          website: data.site,
          viewsCount: 0,
          isInTrash: false,
        });

        const response = await newTour.get();

        if (response.exists) {
          localStorage.setItem(storageProject, response.id);
          localStorage.setItem(storageProjectTitle, response.data().name);
          localStorage.setItem(storageProjectCode, response.data().projectCode);
        } else {
          throw new Error();
        }

        history.push('/upload');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

          return;
        }

        if (err.name === 'CepPromiseError') {
          toast.error('Ocorreu um erro na busca pelo CEP. Tente novamente.');
          return;
        }

        toast.error(
          'Ocorreu um erro ao criar o projeto. Por favor tente novamente'
        );

        console.log(err);
      }
    },
    [
      history,
      organization.data.name,
      organization.logoPhoto,
      validateCep,
      validateForm,
      corrector,
    ]
  );

  useEffect(() => {
    async function loadUsers() {
      const response = await firestore
        .collection('organizacoes')
        .where('data.name', '==', organization.data.name)
        .get();

      response.docs.forEach(doc => {
        setUsers(doc.data().users);
      });
    }
    loadUsers();
  }, [organization.data.name]);

  return (
    <Container>
      <Link to="/dashboard">
        <ChevronLeft size={22} />
        Voltar
      </Link>
      <Title>Informações do projeto</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <InputLabel htmlFor="cep">Referência</InputLabel>
        <Input placeholder="Insira um código de referência" name="code" />

        <InputLabel htmlFor="site">Website</InputLabel>
        <Input placeholder="Insira a URL do seu website" name="site" />

        <InputLabel htmlFor="nome">Título</InputLabel>
        <Input name="name" placeholder="Título do projeto" />

        <InputLabel htmlFor="descricao">Descrição: (opcional)</InputLabel>
        <TextArea name="description" placeholder="Adicione uma descrição" />

        <InputLabel htmlFor="cep">CEP</InputLabel>
        <Input placeholder="Informe o CEP" name="cep" />

        <InputLabel htmlFor="corretor">
          Selecione o corretor responsável
        </InputLabel>
        <Users>
          {users.map(user => (
            <UserCard
              key={user.email}
              type="submit"
              onClick={() => {
                setCorrector(user.name);
                toast.success(`Corretor ${user.name} selecionado(a)`);
              }}
            >
              <Tooltip title={user.name}>
                <UserAvatar src={user.profilePhoto}>
                  {!user.profilePhoto && user.name[0]}
                </UserAvatar>
              </Tooltip>
            </UserCard>
          ))}
        </Users>
        <button type="submit">{loading ? 'Carregando...' : 'Avançar'}</button>
      </Form>
    </Container>
  );
};

export default ProjectInfo;
