/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../provider/hooks/auth';

const PrivateRoute = ({
  component: Component,
  isPayment,
  isPrivate = false,
  ...rest
}) => {
  const { user, organization } = useAuth();

  if (!user && isPrivate) {
    return <Redirect to="/" />;
  }

  if (organization) {
    if (!organization.plan && isPayment) {
      return <Redirect to="/plans" />;
    }
  }

  if (user && !isPrivate) {
    return <Redirect to="/home-screen" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;
