import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Container } from './styles';
import { setPeopleDatas, getPeople } from '../../../utils/storage';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErrors';

const CreateOrgs = () => {
  const formRef = useRef(null);
  const people = getPeople();
  const history = useHistory();

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(
            'Sua organização precisa de um nome para divulgação'
          ),
        });

        await schema.validate(data, { abortEarly: false });

        // validar tipo de pessoa e salvar dados no storage:

        if (people === 'Pessoa física') {
          const pfDatas = { name: data.name, cpf: data.cpf };

          setPeopleDatas(pfDatas);
        }

        if (people === 'Pessoa jurídica') {
          const pjDatas = {
            name: data.name,
            cnpj: data.cnpj,
            companyName: data.companyName,
            municipalRegister: data.municipalRegister,
            stateRegister: data.stateRegister,
          };

          setPeopleDatas(pjDatas);
        }

        history.push('/register-category');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
        }

        toast.error('Houve um erro ao validar as informações, tente novamente');
      }
    },
    [people, history]
  );

  return (
    <Container>
      <Form onSubmit={handleSubmit} ref={formRef}>
        {people === 'Pessoa física' && (
          <>
            <Input placeholder="Nome para divulgação*" name="name" />
            <Input placeholder="CPF" name="cpf" />
            <button type="submit">Próximo</button>
          </>
        )}

        {people === 'Pessoa jurídica' && (
          <>
            <Input placeholder="Nome para divulgação*" name="name" />

            <Input placeholder="CNPJ" name="cnpj" />

            <Input placeholder="Razão social" name="companyName" />

            <Input placeholder="Inscrição municipal" name="municipalRegister" />

            <Input placeholder="Inscrição estadual" name="stateRegister" />
            <button type="submit">Próximo</button>
          </>
        )}
      </Form>
    </Container>
  );
};

export default CreateOrgs;
