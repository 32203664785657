const data = {
  customer: {
    name: 'Guilherme Oliveira',
    email: 'guilhermegso1019@gmail.com',
    phone: '19984204981',
    cpf: '43242624890',
    birthday: '26/08',
  },
  address: {
    zipcode: '13184410',
    street: 'Rua Benedicta Pires de Assis',
    neighborhood: 'Loteamento Remanso Campineiro',
    street_number: '371',
    state: 'SP',
    city: 'Hortolândia',
  },
};

export default data;
