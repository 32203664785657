import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import pf from '../../../assets/pf.png';
import pj from '../../../assets/pj.png';

import { Container } from './styles';
import { setPeople } from '../../../utils/storage';

const SelectPeopleTYpe = () => {
  const history = useHistory();

  const handleChoose = useCallback(
    people => {
      if (people === 'pf') {
        setPeople('Pessoa física');
      }

      if (people === 'pj') {
        setPeople('Pessoa jurídica');
      }

      history.push('/insert-organization-datas');
    },
    [history]
  );

  return (
    <Container>
      <button type="button" onClick={() => handleChoose('pf')}>
        <img src={pf} alt="" />
        <strong>Pessoa física</strong>
      </button>
      <button type="button" onClick={() => handleChoose('pj')}>
        <img src={pj} alt="" />
        <strong>Pessoa jurídica</strong>
      </button>
    </Container>
  );
};

export default SelectPeopleTYpe;
