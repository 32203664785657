import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 40px auto;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-bottom: 40px;

  svg {
    height: 120px;
    width: 120px;

    color: #6ac87e;
  }
`;

export const Title = styled.div`
  max-width: 60%;
  h3 {
    color: #444;
    font-size: 36px;
    font-weight: normal;
  }
`;

export const Cards = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 5px;
  border-radius: 8px;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 15px;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    height: 100px;
    margin-bottom: 15px;
  }

  h1 {
    color: #312e38;
  }

  p {
    font-size: 19px;
    margin: 10px 0;
    color: #312e38;
    font-weight: 400;
    text-align: center;
    max-width: 70%;
  }

  button {
    width: 260px;
    height: 42px;
    background: #3e348b;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border-radius: 6px;
    box-shadow: 2px 2px 3px rgba(62, 52, 139, 0.5);
    transition: all 0.3s;

    &:hover {
      background: ${shade(0.2, '#3e348b')};
      transform: translateY(-3px);
    }
  }
`;
