import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  form {
    h1 {
      font-size: 22px;
      color: ${({ theme }) => theme.text.primary};
      margin-bottom: 20px;
      text-align: center;
    }

    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  button {
    height: 40px;
    width: 100%;
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-size: 16px;
    border: none;
    margin-top: 8px;
    border-radius: 8px;
  }
`;
