import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromBottom = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const scaleUp = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
    transform: scale(1.06);
  }

  to {
    transform: translateY(0);
    opacity: 1;
    transform: scale(1);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
`;

export const Header = styled.div`
  height: 90px;
  padding: 5px 15px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: #f8f8f8;
`;

export const Content = styled.div`
  flex: 1;
  background: #f8f8f8;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    margin-top: 15px;

    input[type="file"] {
      display: none;
    }

    label {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 19px;
      font-weight: bold;
      color: #312e38;
    }
  }

  img, svg {
    height: 150px;
    width: 150px;
    border-radius: 75px;
    box-shadow: 2px 4px 7px rgba(0, 0, 0, .4);
    animation: ${scaleUp} 0.5s;
  }

  strong {
    margin: 20px 0;
    font-size: 22px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    color: #312e38;
    animation: ${appearFromBottom} 0.8s;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 320px;
    animation: ${appearFromBottom} 0.8s;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 36px;
  background: #2C348C;
  box-shadow: 2px 2px 6px rgba(44, 52, 140, 0.4);
  border: none;
  border-radius: 6px;
  margin-top: 5px;

  color: #fff;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  transition: all 0.4s;

  &:hover {
    transform: translateY(-3px);
    background: ${shade(0.2, '#2c348c')};
  }
`;