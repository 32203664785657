import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70vh;

  h1 {
    text-align: center;
  }
`;

export const AreaUpload = styled.div`
  width: 112px;
  height: 112px;
  border-radius: 56px;
  position: relative;
  border: 4px solid ${({ theme }) => theme.colors.primary};

  div {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 32px;
    left: 32px;
  }

  input {
    z-index: 999;
    display: block;
    font-size: 999px;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    text-align: right;
    cursor: pointer !important;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 56px;
  }

  h1 {
    margin-top: 12px;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  strong {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 18px;
  }
`;
