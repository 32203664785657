import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  Content,
  AreaCard,
  Card,
  Header,
  NoOrganization,
} from './styles';

import noLogo from '../../assets/no-notify.png';
import Org from '../../assets/first-steps.png';

import { useAuth } from '../../provider/hooks/auth';
import firestore from '../../config/firestore';

const Home = () => {
  const [organizations, setOrganizations] = useState([]);
  const { user, updateOrg } = useAuth();

  const history = useHistory();

  useEffect(() => {
    async function getOrganizations() {
      const response = user.organization;

      const userOrgs = response.map(organization => {
        return organization;
      });

      setOrganizations(userOrgs);
    }

    getOrganizations();
  }, [user.organization]);

  const handleChooseOrganization = useCallback(
    async ident => {
      const organization = await firestore
        .collection('organizacoes')
        .where('data.name', '==', ident)
        .get();

      organization.docs.forEach(doc => {
        updateOrg(doc.data());
      });

      history.push('/dashboard');
    },
    [updateOrg, history]
  );

  return (
    <Container>
      <Content>
        <Header>
          <h1>Bem Vindo!</h1>
        </Header>

        {organizations.length === 0 && (
          <NoOrganization>
            <div className="left">
              <strong>
                {`Olá ${user.name}, você ainda não faz parte de nenhuma organização.`}
              </strong>
              <span>
                <Link to="/first-steps">Crie sua própria organização </Link>
                ou insira um link de convite para uma organização já existente
              </span>
              <Link to="/first-steps" className="right">
                Primeiros passos
              </Link>
            </div>
            <div>
              <img src={Org} alt="First steps" />
              <Link to="/first-steps" className="right">
                Primeiros passos
              </Link>
            </div>
          </NoOrganization>
        )}

        <AreaCard>
          {organizations.map(organization => (
            <Card key={organization.name}>
              <button
                onClick={() => handleChooseOrganization(organization.name)}
                type="button"
              >
                <img src={organization.logoPhoto || noLogo} alt="noLogo" />
                <strong>{organization.name}</strong>
                <span>{organization.category}</span>
              </button>
            </Card>
          ))}
        </AreaCard>
      </Content>
    </Container>
  );
};

export default Home;
