import React from 'react';
import { Container } from './styles';

import { useAuth } from '../../provider/hooks/auth';

import Aside from '../../components/Aside';
import { NoOwner, PhysicalPerson, LegalPerson } from './components';

const MyBusiness = () => {
  const { organization, user } = useAuth();

  return (
    <Aside page="Meu negócio">
      <Container>
        {organization.owner !== user.id ? (
          <NoOwner />
        ) : organization.people === 'Pessoa física' ? (
          <PhysicalPerson />
        ) : (
          <LegalPerson />
        )}
      </Container>
    </Aside>
  );
};

export default MyBusiness;
