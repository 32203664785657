/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(#007cfc 65%, #fff 35%);
  padding: 50px;

  strong {
    font-size: 38px;
    color: #fff;
    animation: ${appearFromRight} 0.4s;
  }

  p {
    font-size: 22px;
    color: #fdfdfd;
    margin: 10px 0;
    animation: ${appearFromRight} 0.4s;
  }

  form {
    margin-top: 30px;
    width: 480px;
    display: flex;
    flex-direction: column;
  }

  button {
    position: absolute;
    bottom: 10%;
    right: 10%;
    margin-top: 18px;
    height: 50px;
    width: 150px;
    color: #fff;
    background: #007cfc;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    transition: all 0.3s;

    &:hover {
      border: 2px solid #007cfc;
      background: #fff;
      color: #007cfc;
    }
  }
`;
