import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas } from 'react-three-fiber';
import { ExpandMore } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

import firestore from '../../../config/firestore';
import AppProvider from '../../../provider';
import { storageImages } from '../../../constants/storage';

import { useEvent } from '../../../provider/hooks/events';

import Controls from './Controls';
import Scene from './Scene';

import {
  Container,
  Preview,
  ArrowButton,
  Organization,
  PreviewCarousel,
  PerformCarousel,
  HotspotName,
  LoadingContainter,
  Loading,
} from './styles';

const View = () => {
  const { projectCode } = useParams();

  const [images, setImages] = useState([]);
  const [logoOrg, setLogoOrg] = useState('');
  const [isShown, setIsShown] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isOverIndex, setOverIndex } = useEvent();

  useEffect(() => {
    async function getImages() {
      try {
        const response = await firestore
          .collection('tours360')
          .where('projectCode', '==', projectCode)
          .get();

        response.docs.forEach(project => {
          setLogoOrg(project.data().organizationLogo);
          setImages(JSON.parse(project.data().images));

          localStorage.setItem(storageImages, project.data().images);
        });

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setHasError(true);
      }
    }
    getImages();
  }, [projectCode, currentIndex]);

  useEffect(() => {
    const myImages = JSON.parse(localStorage.getItem(storageImages));
    const imageLabel = myImages[currentIndex].label;
    setOverIndex(imageLabel);
    setIsLoading(true);
  }, [currentIndex]);

  const onClickHotspot = useCallback(to => {
    setCurrentIndex(to);
  }, []);

  const handleNavigateFromPreview = useCallback(index => {
    setIsShown(false);
    setCurrentIndex(index);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Container>
      {hasError ? (
        <h3>Occorreu um erro, por favor recarregue e pagina</h3>
      ) : (
        <AppProvider>
          <Canvas>
            <Scene
              images={
                images.length > 0
                  ? images
                  : JSON.parse(localStorage.getItem(storageImages))
              }
              onPointerMove={() => setIsShown(false)}
              currentIndex={currentIndex}
              onClickHotspot={onClickHotspot}
            />
            <Controls />
          </Canvas>
          {isLoading && (
            <LoadingContainter>
              <Loading />
            </LoadingContainter>
          )}
          <Preview isShown={isShown}>
            <PerformCarousel responsive={responsive}>
              {images.map((image, index) => (
                <PreviewCarousel
                  type="button"
                  onClick={() => handleNavigateFromPreview(index)}
                  image={image.image}
                  key={image.label}
                >
                  <h1>{image.label}</h1>
                </PreviewCarousel>
              ))}
            </PerformCarousel>
          </Preview>
          <HotspotName onClick={() => setIsShown(false)}>
            <h1>{isOverIndex}</h1>
          </HotspotName>
          <Organization onClick={() => setIsShown(false)}>
            <img src={logoOrg} alt="Logo" />
          </Organization>

          {!isShown ? (
            <ArrowButton onClick={() => setIsShown(!isShown)}>
              <ExpandMore fontSize="large" />
            </ArrowButton>
          ) : null}
        </AppProvider>
      )}
    </Container>
  );
};

export default View;
