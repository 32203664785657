import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100wh;
  background: ${({ theme }) => theme.colors.primary};

  padding: 0 45px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  @media (max-width: 768px) {
    justify-content: center;
  }

  div {
    width: 100%;
    max-width: 590px;

    h1 {
      font-size: 32px;
      font-weight: 400;
      color: #fff;

      @media (max-width: 480px) {
        font-size: 30px;
      }
    }
    p {
      margin-top: 15px;
      font-size: 18px;
      color: #fff;
      font-weight: 300;
      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
    button {
      height: 50px;
      background: #5b5fff;
      color: #fff;
      font-size: 16px;

      width: 222px;
      box-shadow: 0 0 0 2px #ddd;
      border: none;
      border-radius: 8px;

      margin-top: 25px;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const AnimationContainer = styled.div`
  width: 100%;
  max-width: 590px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ModalContent = styled.div`
  max-height: 300px;
  min-height: 300px;
  height: 100%;

  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px 0;

  color: #444;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  width: 100%;
  margin-bottom: 20px;

  h1 {
    font-weight: 400;
    font-size: 16px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 2px 2px 5px #ddd;

  padding: 10px;

  border-radius: 4px;

  max-width: 320px;
  width: 100%;
  margin: 0 auto;

  svg {
    color: #5b5fff;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;

    strong {
      font-weight: 400;
    }

    svg {
      margin-right: 5px;
    }
  }
`;

export const LeftSide = styled.img`
  height: 50px;
  margin-top: 50px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  max-width: 320px;
  width: 100%;
  margin: 0 auto;
`;

export const Button = styled.button`
  height: 35px;
  border: 0;
  width: 300px;
  background: ${({ theme, cancel }) =>
    cancel ? theme.colors.secondary : theme.colors.primary};
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin-top: 50px;
  margin-left: 8px;
  border-radius: 4px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `};

  &:hover {
    opacity: ${props => (props.isDisabled ? 0.4 : 0.7)};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  p {
    margin-bottom: 20px;
  }

  input {
    margin-top: 8px;
    border: 1px solid #ddd;
    height: 35px;
    border-radius: 4px;
    padding: 0 5px;
    font-size: 14px;

    &:focus {
      border: 1px solid #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    }
  }

  button {
    width: 100%;
    max-width: 320px;
    margin: 35px auto;
  }
`;
