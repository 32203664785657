import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  width: 100%;
  min-height: 38px;
  max-height: 45px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 18px;

  display: flex;
  align-items: center;

  background: #fff;
  & + div {
    margin-top: 8px;
  }

  > button {
    border: none;
    background: none;
    margin-right: -15px;

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  input {
    background: transparent;
    flex: 1;
    border: 0;
    font-size: 16px;
    color: #444;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }

  ${props =>
    props.isErrored &&
    css`
      border: 2px solid #fc8077;
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 1px solid #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    `}

  ${props =>
    props.isFilled &&
    css`
      background: #f5f5f5;
    `}
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;

  p {
    background: #fc8077;

    &::before {
      border-color: #fc8077 transparent;
    }
  }
`;
