import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Input from '../../components/Input';
import Firebase from '../../services/Firebase';
import getValidationErrors from '../../utils/getValidationErrors';

const ForgotPassword = () => {
  const formRef = useRef(null);
  const [confirmation, setConfirmation] = useState('');

  const handleSubmit = useCallback(async data => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Email não pode ser nulo')
          .email('Digite um email válido'),
      });

      await schema.validate(data, { abortEarly: false });

      const api = new Firebase();

      await api.updatePassword(data.email);

      setConfirmation(`
        Enviamos um email de confirmação para ${data.email}, verifique sua caixa de entrada.
      `);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current.setErrors(errors);

        return;
      }

      toast.error('Houve um erro ao redefinir sua senha, tente novamente');
    }
  }, []);

  return (
    <Container>
      {confirmation ? (
        <h1>{confirmation}</h1>
      ) : (
        <Form onSubmit={handleSubmit} ref={formRef}>
          <h1>Informe seu email</h1>
          <Input placeholder="Digite seu email" name="email" type="email" />
          <button type="submit">Redefinir senha</button>
        </Form>
      )}
    </Container>
  );
};

export default ForgotPassword;
