import styled, { keyframes } from 'styled-components';

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  background: ${({ theme }) =>
    `linear-gradient(${theme.colors.primary} 65%, ${theme.background} 35%)`};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: center;

  h1 {
    color: #312e38;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
`;

export const Content = styled.div`
  background: #fff;
  width: 100%;
  max-width: 1000px;
  height: 400px;
  border-radius: 12px;
  overflow-y: auto;

  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.4);
`;

export const AreaCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 8px;

  width: 100%;
  max-width: 980px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  height: 200px;
  width: 230px;

  margin: 10px 5px 0;

  button {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: transparent;
    transition: ease-out all 0.3s;

    color: #312e38;

    strong {
      font-size: 22px;
    }

    span {
      margin: 5px;
      font-size: 18px;
      color: #312e38;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50px;
      margin-bottom: 10px;
      box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      background: #f7f7f7;
    }
  }
`;

export const NoOrganization = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  div {
    animation: ${appearFromBottom} 0.9s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px;

    strong {
      max-width: 350px;
      font-size: 22px;
      color: #333;
      font-weight: 500;
    }

    span {
      margin-top: 15px;
      max-width: 350px;
      font-size: 20px;
      color: #333;
      font-weight: 300;

      a {
        color: #333;
        text-decoration: none;
      }
    }

    img {
      height: 240px;
    }

    a.right {
      width: 130px;
      height: 42px;
      background: #5952ff;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 4px;
      font-weight: bold;
      transition: all 0.5s;

      &:hover {
        opacity: 0.8;
        transform: translateY(-5px);
      }
    }
  }

  div.left {
    align-items: flex-start;
    padding: 0 50px;

    span {
      margin-bottom: 20px;
    }
  }
`;
