import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  min-height: 100vh;
  background: ${({ theme }) =>
    `linear-gradient(${theme.colors.primary} 65%, ${theme.background} 35%)`};
  padding: 10px 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    color: #fff;
    position: absolute;
    top: 20px;
    left: 20px;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Title = styled.h1`
  font-size: 58px;
  color: #fff;
`;

export const UploadContainer = styled.div`
  margin: 30px 0;
  width: 100%;
  max-width: 580px;
  padding: 20px;
  border-radius: 10px;

  background: #fff;
`;

export const GoAhead = styled.button`
  width: 200px;
  height: 52px;

  background: ${({ theme }) => theme.colors.secondary};
  border: none;
  border-radius: 6px;
  color: #fdfdfd;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.4s;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.colors.secondary)};
    color: ${shade(0.3, '#fff')};
  }
`;
