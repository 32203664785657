import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/Input';
import { Container } from './styles';
import Firebase from '../../../services/Firebase';
import { useAuth } from '../../../provider/hooks/auth';
import { getPeople, getData, getCategory, clear } from '../../../utils/storage';

const LastStep = () => {
  const api = new Firebase();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user, updateUser, updateOrg } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        await api.registerOrganization({
          isOwner: true,
          owner: user.id,
          data: getData(),
          category: getCategory(),
          people: getPeople(),
          address: data.address,
          phone: data.phone,
        });

        const updatedUser = await api.getUserById('usuarios', user.id);
        const currentOrg = await api.loadOrgsAssociated();

        updateUser({ ...user, organization: updatedUser.organization });
        updateOrg(currentOrg);
        history.push('/dashboard');
        // limpa os dados persistentes das organizações do storage
        clear();
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },
    [api, history, user, updateUser, updateOrg]
  );

  return (
    <Container>
      <strong>Quase tudo pronto!</strong>
      <p>
        Para finalizar você pode adicionar informações iniciais para uma melhor
        experiência.
        <small style={{ color: '#FFCC00', fontWeight: 'bold' }}>
          <br />
          (Essa etapa é opcional, e você poderá alterar seus dados depois de ter
          sua organização criada.)
        </small>
      </p>

      <Form onSubmit={handleSubmit}>
        <Input name="address" placeholder="Adicione um endereço" />
        <Input name="phone" placeholder="Adicione um telefone pra contato" />

        <button type="submit">{loading ? 'Carregando' : 'Finalizar'}</button>
      </Form>
    </Container>
  );
};

export default LastStep;
