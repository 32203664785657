import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import Axios from 'axios';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { Notifications as Bell } from '@material-ui/icons';
import { Divider, Avatar, CircularProgress, Badge } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useAuth } from '../../provider/hooks/auth';

import {
  Container,
  Content,
  Header,
  User,
  UserInfo,
  UserFunction,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  Footer,
  Button,
  Loading,
  Success,
  Message,
  Notifications,
  Left,
  Right,
  NotifyContainer,
  NoUsers,
} from './styles';

import Aside from '../../components/Aside';
import Modal from './components/Modal';
import Animation from './components/Animation';

import Firebase from '../../services/Firebase';

import firestore from '../../config/firestore';
import nice from '../../assets/animation/nice.json';
import noNotify from '../../assets/no-notify.png';

const animated = makeAnimated();

const Users = () => {
  const { user, organization } = useAuth();

  const api = new Firebase();

  const [loadNotificationsAgain, setLoadNotificationsAgain] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const [usersInOrganization, setUsersInOrganization] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [response, setResponse] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [emailService] = useState(
    'https://us-central1-mytours-1e89f.cloudfunctions.net/sendMailer'
  );

  useEffect(() => {
    async function loadPermissions() {
      const response = await firestore
        .collection('organizacoes')
        .where('data.name', '==', organization.data.name)
        .get();

      response.docs.forEach(doc => {
        const { users } = doc.data();

        const filteredUser = users.filter(
          filtered => filtered.email === user.email
        );

        filteredUser.forEach(current => {
          if (
            current.role === 'Proprietário' ||
            current.role === 'Administrador'
          ) {
            setHasPermission(true);
          } else {
            setHasPermission(false);
          }
        });
      });
    }

    loadPermissions();
  }, [user.email, organization.data.name]);

  const formattedNotifications = useMemo(() => {
    const formatted = notifications.map(notification => {
      const { emittedIn, expiresIn } = notification;
      const formattedEmittedDate = moment(emittedIn).format(
        'DD/MM | hh:mm',
        'pt'
      );

      const formattedExpiredDate = moment(expiresIn).format(
        'DD/MM | hh:mm',
        'pt'
      );

      return {
        ...notification,
        id: notification.id,
        expired: formattedExpiredDate,
        date: formattedEmittedDate,
      };
    });

    return formatted;
  }, [notifications]);

  const handleAddUser = useCallback(
    async e => {
      e.preventDefault();

      try {
        const currentOrganization = organization.data.name;
        const userAlreadyExists = await api.userExists(
          currentOrganization,
          email
        );
        const userAlreadyInvited = await api.userInvited(
          organization.data.name,
          email
        );

        if (userAlreadyExists) {
          toast.error(
            'Usuário não pode ser adicionado pois já faz parte da organização'
          );
        } else if (userAlreadyInvited) {
          toast.error(
            'Usuário não pode ser adicionado pois já lhe foi enviado um convite'
          );
        } else {
          setLoading(true);
          setShowContent(false);
          await Axios.post(emailService, {
            provider: user.name,
            email,
            org: organization.data.name,
            func: role,
            url: `https://home360.com.br/invite/${user.id}?=${role}?=${email}`,
          });

          const notification = await firestore.collection('notifications').add({
            organization: organization.data.name,
            emissor: user.name,
            destiny: email,
            status: 'Pendente',
            emittedIn: moment().toString(),
            expiresIn: moment().add(24, 'h').toString(),
          });

          api
            .expiresInvite(notification.id)
            .then(response => console.log(response));

          setResponse(`Email enviado para ${email}`);
          setSuccess(true);

          setTimeout(() => setShowModal(false), 8000);

          setLoading(false);
          setLoadNotificationsAgain(true);
        }
      } catch (err) {
        toast.error(
          'Houve um erro ao adiconar o novo usuário, tente novamente'
        );
      }
    },
    [email, role, emailService, organization.data.name, user.name, user.id, api]
  );

  const onDeleteNotify = useCallback(
    async id => {
      await firestore.collection('notifications').doc(id).delete();

      const restoredNotifications = notifications.filter(
        notification => notification.id !== id
      );

      setNotifications(restoredNotifications);
    },
    [notifications]
  );

  const onDeleteUser = useCallback(
    async user => {
      const filteredUsers = await api.deleteUser(organization.data.name, user);

      setUsersInOrganization(filteredUsers);
    },
    [organization.data.name, api]
  );

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
    setSuccess(false);
    setShowContent(true);
  }, [showModal]);

  const toggleNotifyModal = useCallback(() => {
    setShowNotify(!showNotify);
  }, [showNotify]);

  const disabled = useMemo(() => {
    if (role === '' || email === '') {
      return true;
    }

    return false;
  }, [role, email]);

  useEffect(() => {
    async function loadUsers() {
      const response = await firestore
        .collection('organizacoes')
        .where('data.name', '==', organization.data.name)
        .get();

      response.docs.forEach(doc => {
        setUsersInOrganization(doc.data().users);
      });
    }

    loadUsers();
  }, [user, organization.data.name]);

  useEffect(() => {
    async function loadNotifications() {
      const response = await firestore
        .collection('notifications')
        .where('organization', '==', organization.data.name)
        .get();

      const currentNotifications = response.docs.map(notification => {
        return { id: notification.id, ...notification.data() };
      });

      setNotifications(currentNotifications);
    }

    loadNotifications();
  }, [organization.data.name, loadNotificationsAgain]);

  return (
    <>
      <Aside page="Usuários" hasNotifications>
        <Container isAvaliableToApplyOpacity={showModal}>
          <Content>
            <Header>
              <Left>
                <strong>
                  Usuários de
                  {'\n'}
                  {organization.data.name}
                </strong>
              </Left>
              {hasPermission ? (
                <Right>
                  <Notifications>
                    {!showNotify ? (
                      !showModal && (
                        <Badge
                          color="primary"
                          badgeContent={notifications.length}
                        >
                          <button
                            onClick={() => setShowNotify(true)}
                            className="notify"
                            type="button"
                          >
                            <Bell />
                          </button>
                        </Badge>
                      )
                    ) : (
                      <button
                        onClick={() => setShowNotify(true)}
                        className="notify"
                        type="button"
                      >
                        <Bell />
                      </button>
                    )}
                  </Notifications>
                  <Button
                    onClick={() => setShowModal(true)}
                    id="new"
                    type="button"
                  >
                    Novo usuário
                  </Button>
                </Right>
              ) : (
                <Button
                  disabled
                  style={{ cursor: 'default', opacity: 0.5 }}
                  id="new"
                  type="button"
                >
                  Novo usuário
                </Button>
              )}
            </Header>
            <Divider />

            {usersInOrganization.map(currentUser => (
              <User key={currentUser.email}>
                <UserInfo>
                  {currentUser.profilePhoto ? (
                    <img
                      src={currentUser.profilePhoto}
                      alt={currentUser.name}
                    />
                  ) : (
                    <Avatar>{currentUser.name[0]}</Avatar>
                  )}
                  <div>
                    <strong>{currentUser.name}</strong>
                    <span>{currentUser.email}</span>
                    {organization.owner === user.id &&
                      currentUser.role !== 'Proprietário' && (
                        <button
                          onClick={() => onDeleteUser(currentUser.email)}
                          type="button"
                        >
                          Excluir
                        </button>
                      )}
                  </div>
                </UserInfo>

                <UserFunction>
                  <strong>{currentUser.role}</strong>
                </UserFunction>
              </User>
            ))}
          </Content>
        </Container>
      </Aside>

      <Modal isLoading={loading} isOpen={showModal} setIsOpen={toggleModal}>
        {showContent && (
          <ModalContent onSubmit={handleAddUser}>
            <ModalHeader>
              <strong>Convidar novos usuários para sua organização</strong>
              <p>
                Envie um convite ao usuário que deseja adicionar. Apenas
                administradores podem enviar convites.
              </p>
            </ModalHeader>
            <ModalBody>
              {/* <Input copy>
               <div>
                 <CopyLink />
                 <input type="text" />
               </div>
               <button type="button">
                 Copiar
                 <FileCopy />
               </button>
             </Input> */}

              <Input isFocused={isFocused}>
                <div>
                  <input
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={e => setEmail(e.target.value)}
                    type="text"
                    placeholder="Informe o email do usuário"
                  />
                </div>
              </Input>
              <ReactSelect
                placeholder="Cargo do usuário"
                onChange={e => setRole(e.value)}
                closeMenuOnSelect
                components={animated}
                options={[
                  { value: 'editor', label: 'Editor' },
                  { value: 'admin', label: 'Administrador' },
                ]}
              />
            </ModalBody>
            <Footer>
              <Button disabled={disabled} isDisabled={disabled} type="submit">
                Enviar
              </Button>
              {/* <Button cancel className="cancel" type="button">
               Cancelar
             </Button> */}
            </Footer>
          </ModalContent>
        )}

        {loading && (
          <Loading>
            <CircularProgress color="primary" />
          </Loading>
        )}

        {success && (
          <Success>
            <Animation source={nice} />
            <Message>
              <h1>{response}</h1>
            </Message>
          </Success>
        )}
      </Modal>

      <Modal
        isLarge={formattedNotifications.length !== 0}
        applyBorder
        isOpen={showNotify}
        setIsOpen={toggleNotifyModal}
      >
        <NotifyContainer>
          <table>
            {formattedNotifications.length && (
              <thead>
                <tr>
                  <th>Emissor</th>
                  <th>Status</th>
                  <th>Destino</th>
                  <th>Emitido</th>
                  <th>Expirado</th>
                </tr>
              </thead>
            )}

            {formattedNotifications.length ? (
              formattedNotifications.map(notification => (
                <tbody key={notification.id}>
                  <tr>
                    <td className="emissor">{notification.emissor}</td>
                    <td className={notification.status}>
                      {notification.status}
                    </td>
                    <td className="email">{notification.destiny}</td>
                    <td>{notification.date}</td>
                    <td>{notification.expired}</td>
                    {notification.status !== 'Pendente' && (
                      <td>
                        <button
                          type="button"
                          onClick={() => onDeleteNotify(notification.id)}
                        >
                          Excluir
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))
            ) : (
              <NoUsers>
                <img src={noNotify} alt="Nenhuma notificação" />
                <h3>Nenhuma notificação emitida</h3>
              </NoUsers>
            )}
          </table>
        </NotifyContainer>
      </Modal>
    </>
  );
};

export default Users;
