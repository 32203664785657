/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const Container = styled.textarea`
  min-height: 100px;
  max-height: 200px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;

  border: 1px solid #ddd;
  border-radius: 8px;

  padding: 15px;

  font-size: 18px;

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `}

  ${props =>
    props.isFilled &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `}

    ${props =>
      props.isFilled &&
      css`
        color: ${({ theme }) => theme.colors.primary};
      `}
`;
