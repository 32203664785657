import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled.div`
  height: 100vh;
  background-color: #007cfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  strong {
    font-size: 32px;
    color: #fff;
    font-weight: bold;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Cards = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  animation: ${appearFromRight} 1.2s;

  button {
    padding: 10px;
    width: 150px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    margin: 0 10px 10px;
    font-weight: bold;
    font-size: 16px;

    transition: all 0.4s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333;

    svg {
      color: #dedede;
      width: 80px;
      height: 120px;
      transition: 0.3s;
    }

    &:hover {
      transform: scale(1.1);
      box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.1);
      color: #ff9000;
    }

    &:hover svg {
      color: #ff9000;
    }
  }
`;
