/* eslint-disable import/no-cycle */
import React, { useState, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Container, InputLabel, Button } from './styles';

import Input from '../../../../components/Input';

import { useAuth } from '../../../../provider/hooks/auth';
import firestore from '../../../../config/firestore';
import getValidationErrors from '../../../../utils/getValidationErrors';

const PhysicalPerson = () => {
  const { organization, updateOrg, user, updateUser } = useAuth();

  const [initialFormData] = useState({
    name: organization.data.name,
    cpf: organization.data.cpf,
    address: organization.address,
    phone: organization.phone,
  });

  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome não pode ser nulo'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await firestore
          .collection('organizacoes')
          .where('data.name', '==', organization.data.name)
          .get();

        response.docs.forEach(async doc => {
          const orgReference = firestore.collection('organizacoes').doc(doc.id);

          orgReference.set(
            {
              address: data.address,
              phone: data.phone,
              data: {
                name: data.name,
                cpf: data.cpf,
              },
            },
            { merge: true }
          );

          const currentOrganization = [
            {
              name: organization.data.name,
              category: organization.category,
              logoPhoto: organization.logoPhoto || null,
            },
          ];
          const users = await firestore
            .collection('usuarios')
            .where('organization', 'array-contains-any', currentOrganization)
            .get();

          users.docs.forEach(doc => {
            const userReference = firestore.collection('usuarios').doc(doc.id);

            const { organization: organizations } = doc.data();

            const allOrganizations = organizations.map(all => all);

            const filteredOrganizations = allOrganizations.filter(
              filtered => filtered.name !== organization.data.name
            );

            orgReference.get().then(async response => {
              const projects = await firestore
                .collection('tours360')
                .where('organization', '==', organization.data.name)
                .get();

              projects.docs.forEach(doc => {
                const projectsReference = firestore
                  .collection('tours360')
                  .doc(doc.id);

                projectsReference.set(
                  {
                    ...doc.data(),
                    organization: response.data().data.name,
                  },
                  { merge: true }
                );
              });
              updateOrg(response.data());
              userReference.set(
                {
                  organization: [
                    ...filteredOrganizations,
                    {
                      name: response.data().data.name,
                      category: response.data().category,
                      logoPhoto: response.data().logoPhoto || null,
                    },
                  ],
                },
                { merge: true }
              );

              userReference.get().then(response => {
                if (response.data().id === user.id) {
                  updateUser(response.data());
                }
              });
            });
          });
        });

        toast.success('Seus dados foram alterados com sucesso');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

          return;
        }

        console.log(err);
        toast.error('Houve um erro ao salvar as alterações');
      }
    },
    [
      user,
      updateOrg,
      updateUser,
      organization.data.name,
      organization.category,
      organization.logoPhoto,
    ]
  );

  return (
    <Container>
      <Form ref={formRef} initialData={initialFormData} onSubmit={handleSubmit}>
        <InputLabel>Nome da sua organização</InputLabel>
        <Input name="name" />

        <InputLabel>CPF</InputLabel>
        <Input name="cpf" />

        <InputLabel>Endereço</InputLabel>
        <Input name="address" />

        <InputLabel>Telefone</InputLabel>
        <Input name="phone" />

        <Button type="submit">Salvar mudanças</Button>
      </Form>
    </Container>
  );
};

export default PhysicalPerson;
