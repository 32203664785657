import React, { useCallback, useState } from 'react';
import { AddAPhoto } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import bcrypt from 'bcryptjs';
import { toast } from 'react-toastify';
import Aside from '../../components/Aside';
import { Container, Content, AreaUpload, Loading } from './styles';
import { useAuth } from '../../provider/hooks/auth';
import firestore from '../../config/firestore';
import storage from '../../config/storage';

const Config = () => {
  const { user, organization, updateOrg } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    async event => {
      const file = event.target.files[0];

      const storageRef = storage.ref();
      const profilesRef = storageRef.child('/logo_org');

      let imageName = await bcrypt.hash('home', 5);
      imageName = imageName.replace('/', '@');
      const imageRef = profilesRef.child(`${imageName}${file.name}`);
      const uploadTask = imageRef.put(file);

      uploadTask.on(
        'state_changed',
        () => {
          setLoading(true);
        },
        err => {
          toast.error(err);
        },
        async () => {
          const currentOrganizations = await firestore
            .collection('organizacoes')
            .where('owner', '==', user.id)
            .get();

          currentOrganizations.docs.forEach(doc => {
            const orgRef = firestore.collection('organizacoes').doc(doc.id);

            uploadTask.snapshot.ref.getDownloadURL().then(async downloadURL => {
              console.log('File available at', downloadURL);

              orgRef.set(
                {
                  logoPhoto: downloadURL,
                },
                { merge: true }
              );

              const currentOrganization = [
                {
                  name: organization.data.name,
                  category: organization.category,
                  logoPhoto: organization.logoPhoto || null,
                },
              ];

              const users = await firestore
                .collection('usuarios')
                .where(
                  'organization',
                  'array-contains-any',
                  currentOrganization
                )
                .get();

              users.docs.forEach(doc => {
                const userReference = firestore
                  .collection('usuarios')
                  .doc(doc.id);

                const { organization: organizations } = doc.data();

                const allOrganizations = organizations.map(all => all);

                const filteredOrganizations = allOrganizations.filter(
                  filtered => filtered.name !== organization.data.name
                );

                orgRef.get().then(async response => {
                  const projects = await firestore
                    .collection('tours360')
                    .where('organization', '==', organization.data.name)
                    .get();

                  projects.docs.forEach(doc => {
                    const projectsReference = firestore
                      .collection('tours360')
                      .doc(doc.id);

                    projectsReference.set(
                      {
                        ...doc.data(),
                        organizationLogo: downloadURL,
                      },
                      { merge: true }
                    );
                  });
                  updateOrg(response.data());
                  userReference.set(
                    {
                      organization: [
                        ...filteredOrganizations,
                        {
                          name: response.data().data.name,
                          category: response.data().category,
                          logoPhoto: downloadURL,
                        },
                      ],
                    },
                    { merge: true }
                  );
                });

                setLoading(false);
              });
            });
          });
        }
      );
    },
    [
      user,
      updateOrg,
      organization.data.name,
      organization.category,
      organization.logoPhoto,
    ]
  );

  return (
    <Aside page="Configuraçoes">
      <Container>
        <Content>
          <AreaUpload>
            <input
              onChange={e => handleChange(e)}
              type="file"
              accept="image/*"
            />
            <div>
              <AddAPhoto fontSize="large" htmlColor="#5952FF" />
            </div>
            <img src={organization.logoPhoto || null} alt="" />
          </AreaUpload>
          <h1>Escolha um logo</h1>
          <h3>O logo escolhido será destacado em seus projetos criados</h3>

          {loading && (
            <Loading>
              <strong>Seu upload está sendo feito, aguarde...</strong>
              <CircularProgress />
            </Loading>
          )}
        </Content>
      </Container>
    </Aside>
  );
};

export default Config;
