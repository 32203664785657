import styled, { keyframes } from 'styled-components';

export const appearFromBotton = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  background: linear-gradient(#007cfc 65%, #fff 35%);

  display: flex;
  align-items: center;
  justify-content: center;

  form {
    animation: ${appearFromBotton} 1s;

    border-radius: 12px;
    background-color: #fff;
    width: 480px;
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }

  button {
    margin-top: 12px;
    height: 50px;
    color: #fff;
    background: #007cfc;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.5s;

    &:hover {
      background: #fff;
      border: 3px solid #007cfc;
      color: #007cfc;
    }
  }
`;
