import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import firstStepsImage from '../../../assets/first-steps.png';

const FirstSteps = () => {
  return (
    <Container>
      <div>
        <strong>Primeiros passos</strong>
        <p>
          Para criar projetos na nossa plataforma você precisa cadastrar seu
          negócio de acordo com as caracteristicas que o compõe.
        </p>

        <span>Siga os passos a seguir para realizar o seu cadastro!</span>

        <Link to="/select-people-type">Começar</Link>
      </div>

      <img src={firstStepsImage} alt="Pessoas mexendo com tecnologia" />
    </Container>
  );
};

export default FirstSteps;
