import React, { useEffect, useState } from 'react';
import { Container } from './styles';

export default function Modal({
  children,
  isOpen,
  setIsOpen,
  isLoading,
  applyBorder,
  isLarge,
}) {
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <Container
      shouldCloseOnOverlayClick={!false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '59%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: isLoading ? 'transparent' : '#fff',
          color: '#000',
          borderRadius: '8px',
          maxWidth: '800px',
          maxHeight: '450px',
          overflow: 'auto',
          border: applyBorder ? '1px solid #ddd' : 'none',
        },
        overlay: {
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      {children}
    </Container>
  );
}
