import styled, { css, keyframes } from 'styled-components';
import Carousel from 'react-multi-carousel';

const modalAnimation = keyframes`
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;

const hideAnimation = keyframes`
  0% {
    opacity: 0.5;
    transition: ease-in-out 0.3s;
  }

  100% {
    opacity: 1;
    transition: ease-out 1s;
  }
`;

export const LoadingContainter = styled.div`
  background: none;
  position: absolute;
  max-width: 90px;
  max-height: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  max-height: 90px;
  max-width: 90px;
  border-radius: 45px;
  background: rgba(255, 255, 255, 0.2);

  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-left: 4px solid transparent;

  animation: ${rotateAnimation};
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  div {
    height: 100vh;
    width: 100vw;
  }
`;

export const BottomContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);

  background: rgba(44, 52, 140, 0.5);
  box-shadow: 2px 2px 8px rgba(44, 52, 140, 0.3);
  border-radius: 8px;

  padding: 10px;

  width: 100%;
  max-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  display: ${props => (props.hide ? 'none' : 'block')};

  animation: ${hideAnimation} 0.5s linear;
`;

export const Hotspot = styled.div`
  text-align: center;

  padding: 5px;

  strong {
    font-size: 18px;
    color: #fff;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;

  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);

  width: 100%;
  max-width: 250px;
  margin: 10px auto;
  padding: 5px;
  border-radius: 5px;
`;

export const Play = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;

  svg {
    font-size: 30px;
    color: #fff;
  }
`;

export const Infos = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;

  svg {
    font-size: 30px;
    color: #fff;
  }
`;

export const ModalContent = styled.div`
  animation: ${modalAnimation} 0.5s linear;
  display: flex;
  flex-direction: column;

  padding: 50px;

  @media (max-width: 480px) {
    padding: 5px;
  }

  h1 {
    margin-bottom: 10px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    p {
      line-height: 18px;
    }

    img {
      height: 100px;
      margin-left: 20px;
      border: 1px solid #ddd;
    }

    .leaflet-container {
      width: 100%;
      height: 350px;
      border-radius: 8px;
      margin-bottom: 24px;

      @media (max-width: 480px) {
        display: none;
      }
    }
  }
`;

export const Bottom = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 2px 2px 5px #555;
  border-radius: 8px;
  padding: 10px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const Location = styled.div`
  display: flex;
  flex-direction: column;

  padding: 15px;

  height: 100%;
  min-height: 140px;
  width: 100%;
  max-width: 40%;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  strong {
    margin-bottom: 5px;
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
  }

  div {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const Description = styled.div`
  min-height: 140px;
  max-height: 140px;

  color: #444;

  width: 100%;
  max-width: 50%;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  padding: 15px;

  border-radius: 8px;

  strong {
    margin-bottom: 5px;
  }
`;

export const Preview = styled.div`
  @media (max-width: 450px) {
    max-width: 100%;
    border-radius: 5px;
  }

  ${props =>
    !props.isShown
      ? css`
          opacity: 0;
          visibility: hidden;
          z-index: 0;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `}

  transition: all 0.3s;

  max-height: 250px;

  background: rgba(44, 52, 140, 0.5);
  box-shadow: 2px 2px 8px rgba(44, 52, 140, 0.3);

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  padding: 0 10px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  top: 0;
`;

export const ArrowButton = styled.button`
  background: #fff;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5px;
  border: none;
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #3f51b5;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  padding: 0 40px;
`;

export const Organization = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  max-height: 112px;
  max-width: 112px;

  img {
    outline: none !important;
    opacity: 0.3;
    width: 112px;
    height: 112px;

    border-radius: 56px;

    &::selection {
      color: transparent;
      background: transparent;
    }
  }
`;

export const PreviewCarousel = styled.button`
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  max-height: 230px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  height: 180px;
  width: 300px;
  transition: transform 0.6s;

  &::selection {
    color: transparent;
    background: transparent;
  }

  &:hover {
    transform: scale(1.06);
  }

  h1 {
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 21px;
    font-weight: 600;
    color: #333;
    border-radius: 4px;
  }
`;

export const PerformCarousel = styled(Carousel)`
  max-height: 230px;

  @media (max-width: 480px) {
    max-width: 340px;
    margin: 0 auto;

    &.react-multiple-carousel__arrow .react-multiple-carousel__arrow--right {
      border: 1px solid red;
      margin-top: 50px;
      margin-left: 25px;
    }
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 50px;
  bottom: 30px;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  border: none;

  height: 50px;
  width: 50px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    height: 40px;
    width: 40px;
  }
`;
