import React, { useContext, createContext, useState } from 'react';

const EventContext = createContext({});

export const useEvent = () => {
  const context = useContext(EventContext);

  if (!context) throw new Error('error');

  return context;
};

export default function EventProvider({ children }) {
  let event;
  const [isOverIndex, setIsOverIndex] = useState(null);

  const setOverIndex = index => setIsOverIndex(index);

  const addEvent = e => {
    event = e;
  };

  const getEvent = () => event;

  return (
    <EventContext.Provider
      value={{ addEvent, getEvent, isOverIndex, setOverIndex }}
    >
      {children}
    </EventContext.Provider>
  );
}
