import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import { useAuth } from '../../../../provider/hooks/auth';

const NoOwner = () => {
  const { organization } = useAuth();

  return (
    <Container>
      <img src={organization.logoPhoto} alt={organization.data.name} />
      <h1>
        Você não tem permissão para alterar os dados de
        {'\n'}
        {organization.data.name}
      </h1>
      <Link to="/dashboard">Voltar</Link>
    </Container>
  );
};

export default NoOwner;
