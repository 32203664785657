import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }

  75% {
    opacity: 1;
    transform: translateX(0);
  }

  90% {
    background-color: ${lighten(0.2, 'rgba(247, 109, 109, 0.8)')}
  }

  100% {
    background-color: rgba(247, 109, 109, 0.8);
  }
`;

export const HotspotModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  > button {
    background: none;
    border: none;
    width: 40px;

    position: absolute;
    right: 3px;
    top: -8px;

    svg {
      color: #f44336;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin: 0 auto;
  width: 100%;

  strong {
    color: #353535;
    font-size: 17px;
    text-align: center;
    flex: 1;

    max-width: 180px;
    width: 100%;
  }
`;

export const ModalInputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    margin: 0 auto;
    margin-top: 80px;

    div {
      margin-bottom: 10px;
    }
  }

  button {
    width: 48%;
    margin: 10px 5px;
    height: 42px;
    background-color: #5952ff;
    border-radius: 7px;

    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    transition: all 0.5s;

    &:hover {
      transform: translateY(-4px);
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;

  button {
    background: #f7f7f7;
    border: none;
    height: 70px;
    width: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #333;
    font-weight: bold;

    transition: all 0.6s;

    &:hover {
      transform: translateY(-5px);
    }

    svg {
      margin-bottom: 5px;
    }
  }
`;

export const ModalDisabledButton = styled.div`
  background: #f7f7f7;
  border: none;
  height: 70px;
  width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #ddd;
  font-weight: bold;

  transition: all 0.6s;

  &:hover {
    transform: translateY(-5px);
  }

  svg {
    color: #ddd;
    margin-bottom: 5px;
  }
`;

export const FloatingContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 260px;
  top: 12vh;
  display: flex;
  flex-direction: column;
`;

export const FloatingCard = styled.div`
  padding: 10px 30px;
  background-color: rgba(247, 109, 109, 0.8);
  margin-bottom: 10px;
  border-radius: 15px 15px 15px 0;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  border: 1px dashed #ccc;
  animation: ${appearFromLeft} 0.4s;

  button {
    font-size: 16px;
    background: transparent;
    border: none;
    color: #fff;
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  height: 10vh;
  padding: 10px;
  background: ${({ theme }) => theme.colors.primary};
  opacity: 0.8;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }

  a {
    margin-right: 10px;
  }

  button {
    margin-right: 20px;
    border: none;
  }

  strong {
    font-size: 22px;
    color: #fff;
  }

  div.middle {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: #fff;
      font-size: 18px;
      height: 85%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    button {
      margin: 0;
      background: transparent;
      border: none;

      svg {
        color: #e7e7e7;
      }
    }
  }
`;

export const SaveButton = styled.button`
  height: 35px;
  border-radius: 8px;
  width: 112px;
  background: #f44336;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  padding: 5px 10px;
  transition: all 0.7s;

  &:hover {
    opacity: 0.8;
  }
`;

export const HeaderFileInput = styled.div`
  height: 50px;
  width: 40px;
  position: relative;

  input[type='file'] {
    display: block;
    font-size: 999px;
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    text-align: right;
    cursor: pointer;
  }
`;

export const HorizontalWrap = styled.div`
  display: flex;
  flex: 1;
`;

export const Sidebar = styled.div`
  height: 100vh;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 10;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 10px;
  padding-bottom: 200px;
`;

export const View = styled.div`
  display: flex;
  flex: 1;
`;
