import styled, { css, keyframes } from 'styled-components';
import Carousel from 'react-multi-carousel';

export const LoadingContainter = styled.div`
  background: none;
  position: absolute;
  max-width: 90px;
  max-height: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  max-height: 90px;
  max-width: 90px;
  border-radius: 45px;
  background: rgba(255, 255, 255, 0.2);

  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-left: 4px solid transparent;

  animation: ${rotateAnimation};
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  div {
    height: 100vh;
    width: 100vw;
  }
`;

export const HotspotName = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.5);
  max-width: 200px;
  max-height: 30px;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 18px;
    font-weight: 200;
    color: #333;
  }
`;

export const Preview = styled.div`
  @media (max-width: 450px) {
    max-width: 100%;
    border-radius: 5px;
  }

  ${props =>
    !props.isShown
      ? css`
          opacity: 0;
          visibility: hidden;
          z-index: 0;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `}

  transition: all 0.3s;

  max-height: 250px;

  background: rgba(0, 0, 0, 0.45);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  padding: 0 10px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  top: 0;
`;

export const ArrowButton = styled.button`
  background: #fff;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5px;
  border: none;
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #3f51b5;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  padding: 0 40px;
`;

export const Organization = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  max-height: 112px;
  max-width: 112px;

  img {
    outline: none !important;
    opacity: 0.3;
    width: 112px;
    height: 112px;

    border-radius: 56px;

    &::selection {
      color: transparent;
      background: transparent;
    }
  }
`;

export const PreviewCarousel = styled.button`
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 10px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  max-height: 230px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  height: 180px;
  width: 300px;
  transition: transform 0.6s;

  &::selection {
    color: transparent;
    background: transparent;
  }

  &:hover {
    transform: scale(1.06);
  }

  h1 {
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 21px;
    font-weight: 600;
    color: #333;
    border-radius: 4px;
  }
`;

export const PerformCarousel = styled(Carousel)`
  max-height: 230px;

  @media (max-width: 480px) {
    max-width: 340px;
    margin: 0 auto;

    &.react-multiple-carousel__arrow .react-multiple-carousel__arrow--right {
      border: 1px solid red;
      margin-top: 50px;
      margin-left: 25px;
    }
  }
`;
