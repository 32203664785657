import React, { useEffect, useState } from 'react';
import { Container } from './styles';

Container.setAppElement('#modal-wrapper');

const Modal = ({ isLarged, children, isOpen, setIsOpen }) => {
  const customStyles = {
    content: {
      height: isLarged ? 400 : 200,
      width: isLarged ? 800 : 400,
      padding: 30,
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      background: '#fff',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      opacity: '0.9',
    },
  };

  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <Container
      shouldCloseOnOverlayClick={!false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={customStyles}
    >
      {children}
    </Container>
  );
};

export default Modal;
