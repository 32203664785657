import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  background: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  height: 20vh;
  background: #3e348b;
  z-index: 3;
  box-shadow: 2px 3px 5px rgba(62, 52, 139, 0.4);
  padding: 0 30px;

  display: flex;
  align-items: center;

  strong {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 200;
    font-size: 25px;
    color: #f8f8f8;
    max-width: 350px;
  }
`;

export const ContentArea = styled.div`
  flex: 1;
  display: flex;

  padding: 10px 30px;
`;

export const Form = styled.form`
  margin-top: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 20px;
  }
  
  .payment {
    margin-top: 20px;

    display: flex;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  div.methods {
    display: flex;

    button {
      background: none;
      border: none;
      border-bottom: 1px solid #ccc;
      font-size: 20px;
      font-weight: bold;
      color: #ccc;
      margin-right: 15px;
      transition: all 0.4s;

      &:hover {
        color: #444;
        border-color: #444;
      }
    }

    button.active {
      color: #312e38;
      border-color: #312e38;
    }
  }

  div.buttons {
    display: flex;
    margin-top: 20px;
  }
`;

export const Input = styled.input.attrs({
  placeholderTextColor: '#312e38',
})`
  animation: ${appearFromRight} 0.5s;
  width: 410px;
  height: 42px;
  border: none;
  box-shadow: none;
  background: none;
  font-weight: 300;
  font-size: 20px;

  transition: all 0.3s;

  color: #312e38;

  &:focus {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 0 10px;
    margin: 5px 0;
  }
`;

export const InputLabel = styled.label`
  animation: ${appearFromRight} 0.5s;
  font-size: 22px;
  font-weight: bold;
  color: #312e38;
`;

export const Button = styled.button`
  animation: ${appearFromRight} 0.5s;
  background: none;
  border: none;
  font-weight: 400;
  font-size: 18px;
  color: #3e348b;
  margin-right: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  width: 150px;
  height: 42px;
  border-radius: 6px;
  border: 0.4px solid #3e348b;
  box-shadow: 2px 3px 4px rgba(62, 52, 139, 0.4);

  transition: all 0.5s;

  &:hover {
    background: #3e348b;
    color: #f8f8f8;
  }
`;

export const CancelButton = styled(Button)`
  color: #fc4747;
  border-color: #fc4747;
  box-shadow: 2px 3px 4px rgba(252, 71, 71, 0.4);

  &:hover {
    background: #fc4747;
  }
`;
