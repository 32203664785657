import React, { useState, useEffect, useMemo } from 'react';
import { useThree } from 'react-three-fiber';
import { TextureLoader, BackSide, LoadingManager } from 'three';

import Nav from '../../../assets/map.png';

function Scene({
  images: tourImages,
  currentIndex,
  onClickHotspot,
  onPointerMove,
}) {
  const { camera } = useThree();

  const [images] = useState(tourImages);
  const [navTexture] = useState(new TextureLoader().load(Nav));
  const [loadingManager] = useState(
    new LoadingManager(
      () => console.log('Load complete'),
      (item, loaded, total) => {
        console.log(`Loaded ${Math.round((loaded / total) * 100, 2)}%`);
      },
      () => console.log('Error loading')
    )
  );

  const [textureLoader] = useState(new TextureLoader(loadingManager));
  const [imageTexture, setImageTexture] = useState(
    textureLoader.load(images[0].image)
  );

  const currentImage = useMemo(() => {
    return images[currentIndex];
  }, [currentIndex, images]);

  useEffect(() => {
    textureLoader.load(
      currentImage.image,
      texture => {
        setImageTexture(texture);
      },
      () => {},
      e => console.log(e)
    );
  }, [currentImage, textureLoader]);

  camera.fov = 75;
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.near = 3;
  camera.far = 1000;

  return (
    <mesh onPointerMove={onPointerMove}>
      <sphereBufferGeometry attach="geometry" args={[20, 40, 20]} />
      <meshBasicMaterial attach="material" map={imageTexture} side={BackSide} />
      {currentImage.links.map(link => (
        <mesh
          key={link.to}
          rotation={[link.rotation.x, link.rotation.y, link.rotation.z]}
          position={[
            link.position.x < 0 ? link.position.x + 0.5 : link.position.x - 0.5,
            link.position.y,
            link.position.z < 0 ? link.position.z + 0.5 : link.position.z - 0.5,
          ]}
          onPointerUp={() => onClickHotspot(link.to)}
        >
          <planeGeometry attach="geometry" args={[2, 2, 1]} />
          <meshBasicMaterial transparent map={navTexture} attach="material" />
        </mesh>
      ))}
    </mesh>
  );
}

export default Scene;
