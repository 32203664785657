import React, { useCallback, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import Firebase from '../../services/Firebase';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';

import { Container, Background, Content, AnimationContainer } from './styles';

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const api = new Firebase();

  const formRef = useRef(null);
  const history = useHistory();

  const handleSignUp = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Insira um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string()
            .min(6, 'No mínimo 6 caracteres')
            .required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, email, password } = data;

        setLoading(true);
        await api.createUserWithEmailAndPassword(name, email, password);

        history.push('/');
        toast.info('Sua conta foi criada, faça login com suas credenciais');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
        }

        toast.error('Houve um erro ao criar sua conta, tente novamente');
        setLoading(false);
      }
    },
    [history, api]
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSignUp}>
            <h1>Bem vindo</h1>
            <p>Faça seu cadastro para poder utilizar nossa plataforma</p>
            <Input placeholder="Seu nome" name="name" />

            <Input placeholder="Seu email" type="email" name="email" />

            <Input
              placeholder="Sua senha"
              isPassword
              type="password"
              name="password"
            />

            <button type="submit">
              {loading ? 'Carregando...' : 'Cadastrar'}
            </button>

            <span>Já possui uma conta?</span>
            <Link to="/signin">Entrar</Link>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
