import React from 'react';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';

import GlobalStyle from './styles/global';
import Routes from './routes';
import AppProvider from './provider';

import themes from './styles/themes';

const App = () => (
  <AppProvider>
    <ThemeProvider theme={themes}>
      <DndProvider backend={HTML5Backend}>
        <Routes />
        <ToastContainer />
      </DndProvider>
    </ThemeProvider>
    <GlobalStyle />
  </AppProvider>
);

export default App;
