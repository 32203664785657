import {
  storagePeopleData,
  storagePeopleType,
  storageBusinessCategory,
} from '../constants/storage';

export const setPeople = people => {
  localStorage.setItem(storagePeopleType, JSON.stringify(people));
};

export const setPeopleDatas = data => {
  localStorage.setItem(storagePeopleData, JSON.stringify(data));
};

export const setCategoryBusiness = category => {
  localStorage.setItem(storageBusinessCategory, JSON.stringify(category));
};

export const getPeople = () => {
  const people = localStorage.getItem(storagePeopleType);

  return JSON.parse(people);
};

export const getData = () => {
  const data = localStorage.getItem(storagePeopleData);

  return JSON.parse(data);
};

export const getCategory = () => {
  const category = localStorage.getItem(storageBusinessCategory);

  return JSON.parse(category);
};

export const clear = () => {
  localStorage.removeItem(storagePeopleType);
  localStorage.removeItem(storagePeopleData);
  localStorage.removeItem(storageBusinessCategory);
};
