import { createGlobalStyle } from 'styled-components';
import 'react-credit-cards/es/styles-compiled.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    font: 14px 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased !important;
  }

  button {
    cursor: pointer;
  }

  input, button, textarea {
    font-family: 'Roboto', sans-serif;
    outline: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

`;
