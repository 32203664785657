import React, { useCallback, useRef, useMemo } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import bcrypt from 'bcryptjs';
import { toast } from 'react-toastify';
import { Avatar } from '@material-ui/core';
import { FiArrowLeft, FiUser } from 'react-icons/fi';
import { useAuth } from '../../provider/hooks/auth';

import firestore from '../../config/firestore';
import storage from '../../config/storage';
import getValidationErrors from '../../utils/getValidationErrors';
import Firebase from '../../services/Firebase';

import Input from '../../components/Input';

import {
  Container,
  Header,
  Content,
  Button
} from './styles';

const Profile = () => {
  const { user, updateUser, organization } = useAuth();
  const formRef = useRef(null);

  const userData = useMemo(() => {
    const { name, email, profilePhoto, id } = user;

    return { name, email, profilePhoto, id };
  }, [user]);

  const handleChange = useCallback(
    async e => {
      toast.info('Aguarde alguns instantes');
      const file = e.target.files[0];

      firestore
        .collection('usuarios')
        .where('id', '==', userData.id)
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(async doc => {
            const userRef = firestore.collection('usuarios').doc(doc.id);
            const storageRef = storage.ref();
            const profilesRef = storageRef.child('/profiles');

            let imageName = await bcrypt.hash('home', 5);
            imageName = imageName.replace('/', '@');
            const imageRef = profilesRef.child(`${imageName}${file.name}`);
            const uploadTask = imageRef.put(file);

            uploadTask.on(
              'state_changed',
              () => {
                console.log('Uploading');
              },
              err => {
                console.log(err);
              },
              () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async downloadURL => {
                    console.log('File available at', downloadURL);

                    userRef.set(
                      {
                        profilePhoto: downloadURL,
                      },
                      { merge: true }
                    );

                    const response = await firestore
                      .collection('organizacoes')
                      .where('data.name', '==', organization.data.name)
                      .get();

                    response.docs.forEach(doc => {
                      const orgReference = firestore
                        .collection('organizacoes')
                        .doc(doc.id);

                      orgReference.get().then(res => {
                        const { users } = res.data();

                        const filteredUsers = users.filter(filtered => {
                          return filtered.email !== user.email;
                        });

                        const getCurrentUserRole = users.filter(filtered => {
                          return filtered.email === user.email;
                        });

                        let userRole;

                        getCurrentUserRole.forEach(user => {
                          userRole = user.role;
                        });

                        orgReference.set(
                          {
                            users: [
                              ...filteredUsers,
                              {
                                name: user.name,
                                email: user.email,
                                profilePhoto: downloadURL,
                                role: userRole,
                              },
                            ],
                          },
                          { merge: true }
                        );
                      });
                    });
                    updateUser({
                      ...user,
                      profilePhoto: downloadURL,
                    });
                  });
              }
            );
          });
        })
        .catch(err => console.log(err));
    },
    [userData.id, updateUser, user, organization.data.name]
  );

  const handleUpdateCredentials = useCallback(
    async data => {
      try {
        const api = new Firebase();

        await api.updatePassword(user.email);

        toast.info('Enviamos com instruções para redefinição da sua senha');
      } catch (err) {
        toast.error(
          'Houve um erro ao alterar suas informações, tente novamente'
        );
      }
    },
    [user]
  );

  return (
    <Container>
      <Header>
        <Link to="/dashboard">
          <FiArrowLeft size={22} color="#312e38" />
        </Link>
      </Header>
      <Content>
        {userData.profilePhoto ? (
          <img src={userData.profilePhoto} alt={userData.name} />
        ) : (
            <FiUser size={22} color="#312e38" />
          )}

        <div>
          <label htmlFor="photo">Clique aqui para atualizar a foto</label>
          <input
            id="photo"
            type="file"
            accept="image/*"
            multiple={false}
            onChange={handleChange}
          />
        </div>

        <strong>{userData.name}</strong>

        <Form ref={formRef} onSubmit={handleUpdateCredentials}>
          <Input
            name="email"
            value={userData.email || 'Seu email'}
            readonly
          />

          <Button type="submit">Redefinir senha</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
