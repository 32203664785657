import React from 'react';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import bcrypt from 'bcryptjs';
import filesize from 'filesize';
import {toast} from 'react-toastify';

import storage from '../../config/storage';
//import firestore from '../../config/firestore';
import { storageImagesURL } from '../../constants/storage'

import Upload from './components/Upload';
import FileList from './components/FileList';

import { Container, Title, UploadContainer, GoAhead } from './styles';

class UploadImages extends React.Component {

  state = {
    uploadedFiles: [],
    imagesURL: [],
  };

  handleNavigate = () => {
    if (this.state.uploadedFiles.length <= 0) {
      toast.error('Faça upload das imagens para iniciar o tour');
    } else {
      this.state.uploadedFiles.map((file, index) => {
        if (!file.uploaded) {
          toast.info('Aguarde o upload finalizar')
        } else if(!file.readableName) {
          toast.info('Informe o nome do ambiente')
        } else {
          const imagesWithLabels = this.state.uploadedFiles.map(
            ({readableName: label, url}) => ({
            label,
            url
          }));

          localStorage.setItem(storageImagesURL, JSON.stringify(imagesWithLabels));

          window.location = "/editor";
        }
        return false;
      });
    }
    console.log(this.state.uploadedFiles);
  }

  handleDelete = async (id, name, url) => {
    const storageRef = storage.ref();
    const imageRef = storageRef.child(name);
    imageRef.delete()
      .then(() => {
        const newFiles = this.state.uploadedFiles.filter(file => file.name !== name);
        this.setState({ uploadedFiles: newFiles });
      })
      .catch(err => toast.error('Ocorreu um erro ao excluir a imagem'));
  }

  handleUpload = async files => {
    let hash = await bcrypt.hash('home', 5);
    hash = hash.split('/')[0];
    const uploadedFiles = files.map(file => {
      const fileNameWithExt = file.name;
      const fileName = fileNameWithExt.substr(0, fileNameWithExt.lastIndexOf('.'))
        || fileNameWithExt;

      return {
        file,
        id: uniqueId(),
        name: hash + file.name,
        readableName: fileName,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null
      }
    });

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });

    //const tourID = localStorage.getItem(storageProject);
    //const docRef = firestore.collection('tours360').doc(tourID);

    const imageURLS = this.state.uploadedFiles.map(
      uploadedFile => uploadedFile.url
    );

    this.setState({ imagesURL: imageURLS });

    /*docRef.set(({
      images: imageURLS,
    }), { merge: true }); */
  };

  processUpload = uploadedFile => {
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadedFile.name);

    const uploadTask = imageRef.put(uploadedFile.file);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = parseInt(Math.round((snapshot.bytesTransferred * 100) / snapshot.totalBytes));
        this.updateFile(uploadedFile.id, { progress })
      },
      (err) => {
        this.updateFile(uploadedFile.id, {
          error: true,
        })
      },
      async () => {
        const url = await imageRef.getDownloadURL();
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          url
        });
      }
    );
  };
  render() {
    return (
      <Container>
        <Link to="/project-info">Voltar</Link>
        <Title>Upload de imagens</Title>

        <UploadContainer>
          <Upload onUpload={this.handleUpload} />
          { !!this.state.uploadedFiles.length && <FileList files={this.state.uploadedFiles} handleDelete={this.handleDelete} /> }
        </UploadContainer>

        <GoAhead onClick={this.handleNavigate}>Avançar</GoAhead>
      </Container>
    )
  }
};

export default UploadImages;
