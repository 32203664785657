import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { People, FeaturedPlayList, Business } from '@material-ui/icons';
import {
  Container,
  Content,
  AnimationContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RightSide,
  LeftSide,
  Button,
  Form,
} from './styles';
import { useAuth } from '../../provider/hooks/auth';

import Firebase from '../../services/Firebase';
import Animation from '../Users/components/Animation';
import Modal from './components/Modal';

import happy from '../../assets/animation/happy.json';
import firestore from '../../config/firestore';

const Invite = () => {
  const { user } = useAuth();

  const { user_id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [orgName, setOrgName] = useState('');
  const [orgLogoURL, setOrgLogoURL] = useState('');
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const [showInvite, setShowInvite] = useState(false);
  const [showInfos, setShowInfos] = useState(true);
  const [loading, setLoading] = useState(false);

  const [membersCount, setMembersCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);

  const role = useMemo(() => {
    const currentUserRole = location.search;
    const replacedParams = currentUserRole.replace('?=', ' ');
    const roleInParam = replacedParams.replace('?=', ' ');
    const [, role, currentEmail] = roleInParam.split(' ');

    setCurrentUserEmail(currentEmail);

    switch (role) {
      case 'editor':
        return 'Editor';
      case 'admin':
        return 'Administrador';
      default:
        break;
    }
  }, [location.search]);

  const isDisabled = useMemo(() => {
    if (!name || !password) {
      return true;
    }

    return false;
  }, [name, password]);

  const handleCreateAccount = useCallback(
    async e => {
      e.preventDefault();
      const api = new Firebase();
      if (!user) {
        try {
          setLoading(true);
          await api.acceptInvite(currentUserEmail);
          const response = await firestore
            .collection('organizacoes')
            .where('owner', '==', user_id)
            .get();

          let organization;

          response.docs.forEach(response => {
            organization = response.data();
          });

          const newUser = await api.createUserWithEmailAndPassword(
            name,
            currentUserEmail,
            password
          );

          newUser.get().then(response => {
            const userReference = firestore
              .collection('usuarios')
              .doc(response.id);

            userReference.set(
              {
                organization: [
                  {
                    category: organization.category,
                    name: organization.data.name,
                    logoPhoto: organization.logoPhoto || null,
                  },
                ],
              },
              { merge: true }
            );
          });

          response.docs.forEach(async doc => {
            const orgReference = firestore
              .collection('organizacoes')
              .doc(doc.id);

            orgReference.set(
              {
                users: [
                  ...doc.data().users,
                  {
                    name,
                    email: currentUserEmail,
                    role,
                    profilePhoto: null,
                  },
                ],
              },
              { merge: true }
            );
          });

          setLoading(false);
          history.push('/dashboard');
        } catch (err) {
          console.log(err);
          toast.error('Houve um erro ao criar sua conta, tente novamente');
          setLoading(false);
        }
      }
    },
    [history, user_id, currentUserEmail, role, name, password]
  );

  const toggleModal = useCallback(() => {
    setShowInvite(!showInvite);
    setShowInfos(true);
  }, [showInvite]);

  const handleDimissInvite = useCallback(async () => {
    const api = new Firebase();

    await api.dimissInvite(currentUserEmail);
  }, [history, currentUserEmail]);

  const handleShowForm = useCallback(() => {
    setShowInfos(false);
  }, []);

  useEffect(() => {
    async function getOrganizationLogo() {
      const response = await firestore
        .collection('organizacoes')
        .where('owner', '==', user_id)
        .get();

      response.docs.forEach(async doc => {
        setMembersCount(doc.data().users.length);
        setOrgName(doc.data().data.name);
        setOrgLogoURL(doc.data().logoPhoto);

        const projects = await firestore
          .collection('tours360')
          .where('organization', '==', doc.data().data.name)
          .get();

        const totalProjects = projects.docs.map(project => project.data());

        setProjectsCount(totalProjects.length);
      });
    }

    getOrganizationLogo();
  }, [user_id]);

  return (
    <>
      <Container>
        <Content>
          <div>
            <h1>Convite para Home360</h1>
            <p>
              {`Você foi convidado a ser ${role} na organização "${orgName}"`}
            </p>
            <button onClick={() => setShowInvite(true)} type="button">
              Ver convite
            </button>
          </div>
          <AnimationContainer>
            <Animation custom source={happy} />
          </AnimationContainer>
        </Content>
      </Container>

      <Modal isOpen={showInvite} setIsOpen={toggleModal}>
        <ModalContent>
          {showInfos ? (
            <>
              <ModalHeader>
                <h1>Dados da organização</h1>
              </ModalHeader>
              <ModalBody>
                <RightSide>
                  <div>
                    <Business />
                    <strong>
                      Nome:
                      {'\n'}
                      {orgName}
                    </strong>
                  </div>
                  <div>
                    <People />
                    <strong>
                      Membros:
                      {'\n'}
                      {membersCount}
                    </strong>
                  </div>
                  <div>
                    <FeaturedPlayList />
                    <strong>
                      Projetos:
                      {'\n'}
                      {projectsCount}
                    </strong>
                  </div>
                </RightSide>
                <LeftSide src={orgLogoURL} />
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleShowForm} type="button">
                  Aceitar
                </Button>
                <Button onClick={handleDimissInvite} cancel type="button">
                  Recusar
                </Button>
              </ModalFooter>
            </>
          ) : (
            <Form onSubmit={handleCreateAccount}>
              <p>
                Para aceitar o convite você deve criar uma conta, termine seu
                cadastro para continuar
              </p>
              <input type="text" value={currentUserEmail} readOnly />
              <input
                type="text"
                placeholder="Seu nome"
                onChange={e => setName(e.target.value)}
              />
              <input
                type="password"
                placeholder="Sua senha"
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                disabled={isDisabled}
                isDisabled={isDisabled}
                type="submit"
              >
                {loading ? 'Carregando...' : 'Criar conta'}
              </Button>
            </Form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Invite;
