import styled, { css } from 'styled-components';

const statusVariations = {
  Pendente: css`
    color: #ff9000;
  `,
  Recusado: css`
    color: #f00;
  `,
  Aceito: css`
    color: #5be53c;
  `,
};

export const Container = styled.div`
  margin-top: 85px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 500px;
  height: 100vh;

  margin: 0 auto;

  overflow: auto;

  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  max-width: 730px;
  margin: 10px auto;
`;

export const Left = styled.div``;

export const Right = styled.div`
  display: flex;
  align-items: center;

  button.notify {
    background: none;
    border: 0;

    svg {
      color: ${({ theme }) => theme.text.secondary};
    }
  }
`;

export const Notifications = styled.div`
  margin-right: 22px;

  span {
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
  }
`;

export const User = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 28px;
  height: 80px;
  width: 100%;

  border-bottom: 1px solid #ddd;

  div {
    button {
      width: 50px;
      display: flex;
      justify-content: flex-start;
      background: none;
      border: 0;
      color: #f00;
      font-weight: bold;
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;

  margin-left: 12px;

  img {
    height: 42px;
    width: 42px;
    border-radius: 21px;

    margin-right: 8px;
  }

  .MuiAvatar-root {
    background: ${({ theme }) => theme.colors.primary};
    height: 42px;
    width: 42px;
    border-radius: 21px;

    margin-right: 8px;
  }

  div {
    display: flex;
    flex-direction: column;
  }
`;
export const UserFunction = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  min-width: 100px;

  strong {
    font-weight: 400;
  }
`;

export const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 405px;

  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  padding: 40px 0;
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 400;
    margin-top: 20px;

    line-height: 18px;
    color: #444;
  }
  strong {
    font-weight: 400;
    color: #444;
    font-size: 18px;
  }
`;

export const ModalBody = styled.div`
  margin-top: 25px;
`;

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 38px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #ddd;

  ${props =>
    props.isFocused &&
    css`
      border: 1px solid #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    `};
  margin-bottom: 20px;

  div {
    display: flex;
    align-items: center;
    flex: 1;

    padding: 0 10px;

    svg {
      margin-right: 8px;
      color: #444;
    }

    input {
      width: 100%;
      color: #444;
      border: none;
      background: none;
      font-size: 14px;
    }
  }
  button {
    width: 130px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${props => (props.copy ? '#ff9000' : '#ddd')};
    color: ${props => (props.copy ? '#fff' : '#444')};

    font-size: 16px;

    border-radius: 8px;

    svg {
      margin-left: 3px;
      color: ${props => (props.copy ? '#fff' : '#444')};
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
`;

export const Button = styled.button`
  border: 0;
  margin-left: 5px;
  width: 144px;
  height: 45px;
  color: #fff;
  font-size: 16px;
  background: ${({ cancel, theme }) =>
    cancel ? theme.colors.secondary : theme.colors.primary};
  border-radius: 8px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `};

  &:hover {
    opacity: ${props => !props.isDisabled && 0.7};
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none !important;
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: none !important;

  min-height: 400px;
`;

export const Message = styled.div`
  color: #444;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: inherit;
  }
  h1 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const NotifyContainer = styled.section`
  padding: 0 30px;
  table {
    width: 100%;

    th {
      padding: 20px 0;
      color: #222;
      font-weight: normal;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

    td {
      font-size: 14px;
      font-weight: normal;
      color: #444;

      max-width: 100px;

      padding: 20px 0;

      &.emissor {
        max-width: 150px;
        word-break: all;
      }
      &.email {
        max-width: 220px;
      }
      &.Pendente {
        color: #ff9000;
        font-weight: bold;
      }
      &.Aceito {
        color: #5be53c;
        font-weight: bold;
      }
      &.Recusado {
        color: #f00;
        font-weight: bold;
      }
      &.Expirado {
        color: #f00;
        font-weight: bold;
      }

      button {
        border: 0;
        background: none;
        color: #f00;
      }
    }
  }
`;

export const Emissor = styled.span``;

export const Destiny = styled.span`
  width: 100%;
  height: 100%;

  word-break: break-all;
`;

export const Status = styled.strong`
  ${props => statusVariations[props.status]};
`;

export const Date = styled.span``;

export const MarkAsView = styled.button`
  border: 0;
  background: none;

  svg {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Expires = styled.span``;

export const NoUsers = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 20px 0;

  img {
    height: 300px;
  }
`;
