import React, { useContext, createContext, useState, useCallback } from 'react';
import Firebase from '../../services/Firebase';
import { storageUser, storageOrganization } from '../../constants/storage';

const AuthContext = createContext({});
const api = new Firebase();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const user = JSON.parse(localStorage.getItem(storageUser));

    if (user) return { user };

    return {};
  });

  const [organization, setOrganization] = useState(() => {
    const organization = JSON.parse(localStorage.getItem(storageOrganization));

    if (organization) return { organization };

    return {};
  });

  const signIn = useCallback(async (email, password) => {
    const response = await api.authenticateUser(email, password);
    const { refreshToken, uid, email: currentUser } = response;

    // request para collection de usuarios para pegar todas informações
    // do usuario que está logando, mentira só o nome e a foto
    const respUser = await api.getUserById('usuarios', uid);

    const { name, profilePhoto, organization } = respUser;

    const data = {
      id: uid,
      token: refreshToken,
      email: currentUser,
      organization,
      name,
      profilePhoto,
    };

    localStorage.setItem(storageUser, JSON.stringify(data));
    setData({ user: data });
  }, []);

  const updateUser = useCallback(data => {
    localStorage.setItem(storageUser, JSON.stringify(data));
    setData({ user: data });
  }, []);

  const updateOrg = useCallback(data => {
    localStorage.setItem(storageOrganization, JSON.stringify(data));
    setOrganization({ organization: data });
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();

    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signOut,
        signIn,
        updateUser,
        updateOrg,
        organization: organization.organization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
