import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Business from '../pages/Business';
import Config from '../pages/Config';
import SelectPeopleType from '../pages/Assistents/SelectPeopleType';
import Dashboard from '../pages/Dashboard';
import FirstSteps from '../pages/Assistents/FirstSteps';
import LastStep from '../pages/Assistents/LastStep';
import InsertOrganizationDatas from '../pages/Assistents/InsertOrganizationDatas';
import OrgCategory from '../pages/Assistents/OrgCategory';
import Profile from '../pages/Profile';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ProjectInfo from '../pages/ProjectInfo';
import UploadImages from '../pages/UploadImages';
import Editor from '../pages/Editor';
import EditorView from '../pages/Editor/View';
import View from '../pages/View';
import Invite from '../pages/Invite';
import Route from './Route';
import TourRoute from './TourRoute';
import InviteRoute from './InviteRoute';
import Users from '../pages/Users';
import Home from '../pages/Home';
import ForgotPassword from '../pages/ForgotPassword';
import Plans from '../pages/Plans';
import Payment from '../pages/Payment';

import Landing from '../pages/Landing';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* globals route */}
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/plans" component={Plans} isPrivate />
      <Route path="/payment/:plan" component={Payment} isPrivate />

      {/* users route */}
      <Route path="/signin" exact component={SignIn} />
      <Route path="/" exact component={Landing} />
      <Route path="/signin" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/profile" component={Profile} isPrivate />

      {/* organizations route */}
      <InviteRoute path="/invite/:user_id" component={Invite} />
      <Route path="/my-business" component={Business} isPrivate isPayment />
      <Route path="/config" component={Config} isPrivate isPayment />
      <Route path="/users" component={Users} isPrivate isPayment />
      <Route
        path="/select-people-type"
        component={SelectPeopleType}
        isPrivate
      />
      <Route
        path="/insert-organization-datas"
        component={InsertOrganizationDatas}
        isPrivate
      />
      <Route path="/last-step" component={LastStep} isPrivate />
      <Route path="/home-screen" component={Home} isPrivate />
      <Route path="/register-category" component={OrgCategory} isPrivate />
      <Route path="/first-steps" component={FirstSteps} isPrivate />

      {/* projects route */}
      <TourRoute
        path="/view/:organization/:projectCode"
        exact
        component={View}
      />
      <Route path="/project-info" component={ProjectInfo} isPrivate isPayment />
      <Route path="/upload" component={UploadImages} isPrivate isPayment />
      <Route path="/editor" exact component={Editor} isPrivate isPayment />
      <TourRoute
        exact
        path="/editor/view/:organization/:projectCode"
        component={EditorView}
        isPayment
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
