const storageToken = '@HOME_360:TOKEN';
const storageUser = '@HOME_360:USER';
const storageProject = '@HOME_360:PROJECT_ID';
const storageImagesURL = '@HOME_360:IMAGES_URL';
const storageProjectTitle = '@HOME_360:PROJECT_NAME';
const storageProjectCode = '@HOME_360:PROJECT_CODE';
const storageImages = '@HOME_360:IMAGES';
const storageOrganization = '@HOME_360:ORGANIZATION';
const storagePeopleType = '@HOME_360:PEOPLE_TYPE';
const storagePeopleData = '@HOME_360:PEOPLE_DATA';
const storageBusinessCategory = '@HOME_360:BUSINESS_CATEGORY';
const storageImagesToEdit = '@HOME_360:IMAGES_TO_EDIT';

export {
  storagePeopleType,
  storagePeopleData,
  storageBusinessCategory,
  storageToken,
  storageUser,
  storageProject,
  storageImagesURL,
  storageProjectTitle,
  storageProjectCode,
  storageImages,
  storageOrganization,
  storageImagesToEdit,
};
