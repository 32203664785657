import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ArrowDropDown, ArrowDropUp, DeleteSweep } from '@material-ui/icons';

import {
  Container,
  Exclude,
  Check,
  MoveDownButton,
  MoveUpButton,
} from './styles';

import './animation.css';

const Preview = ({
  source,
  onClick,
  index,
  move,
  moveUp,
  moveDown,
  label,
  onChangeLabel,
  handleExcludeImage,
  handleChooseThumbnail,
  checked,
  countImages,
}) => {
  const [, dragRef] = useDrag({
    item: { type: 'IMAGE', index },
    colect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const cardRef = useRef(null);

  const [, dropRef] = useDrop({
    accept: 'IMAGE',
    hover(item, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = cardRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      move(dragIndex, hoverIndex);

      item.index = index;
    },
  });

  dragRef(dropRef(cardRef));

  const imageCount = useMemo(() => {
    const count = `Imagem: ${index + 1}/${countImages}`;

    return count;
  }, [index, countImages]);

  return (
    <Container id={String(index)} ref={cardRef}>
      <button className="btn" type="button" onClick={onClick}>
        <img src={source} alt="Preview" />
      </button>

      <MoveUpButton className="up" onClick={() => moveUp(index)}>
        <ArrowDropUp />
      </MoveUpButton>

      <MoveDownButton className="down" onClick={() => moveDown(index)}>
        <ArrowDropDown />
      </MoveDownButton>

      <input
        className="label"
        type="text"
        defaultValue={label}
        onChange={e => onChangeLabel(index, e.target.value)}
      />

      <Exclude>
        <button onClick={handleExcludeImage} type="button">
          <DeleteSweep />
        </button>

        <strong>{imageCount}</strong>
      </Exclude>

      <Check>
        <strong>Definir como capa</strong>
        <input
          checked={checked}
          onChange={handleChooseThumbnail}
          type="radio"
          name="thumbnail"
        />
      </Check>
    </Container>
  );
};

export default Preview;
