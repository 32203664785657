import React from 'react';

import { Container } from './styles';

const Tooltip = ({ title, className, children }) => (
  <Container className={className}>
    {children}
    <p>{title}</p>
  </Container>
);

export default Tooltip;
