import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 800px;
  width: 100%;
  margin: 180px auto;

  img {
    height: 80px;
    width: 80px;
    border-radius: 40px;
  }

  h1 {
    margin-top: 40px;
    font-size: 19px;
    color: ${({ theme }) => theme.text.primary};
  }
`;
