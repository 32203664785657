import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PersonWithHouses from '../../assets/landing/person-with-houses.svg';
import Lamp from '../../assets/landing/lamp.svg';
import Comments from '../../assets/landing/comments.svg';
import Logo from '../../assets/landing/logo-preto.png';
import Free from '../../assets/landing/free.svg';
import Starter from '../../assets/landing/starter.svg';
import Pro from '../../assets/landing/pro.svg';

import {
  Container,
  Present,
  Content,
  ImageBackground,
  TextArea,
  Menu,
  About,
  LeftSide,
  RightSide,
  Pricing,
  Cards,
  Title,
  Card,
  Contact,
  FormArea,
} from './styles';

function Landing() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  return (
    <Container>
      <Menu id="menu">
        <a href="#present">
          <img src={Logo} alt="Logo" />
        </a>

        <div>
          <a href="#about">Quem Somos</a>
          <a href="#pricing">Preço</a>
          <a href="#contact">Contato</a>
          <Link className="signin" to="/signin">Entrar</Link>
        </div>
      </Menu>
      <Present id="present">
        <ImageBackground />
        <Content>
          <img alt="Person With Houses" src={PersonWithHouses} />
          <TextArea>
            <h1>Divulgue seus imoveis!</h1>
            <p>Crie tours em 360°, vistorias automatizadas e muito mais</p>
            <Link to="/signup">
              <button type="button">Conhecer</button>
            </Link>
          </TextArea>
        </Content>
      </Present>
      <About id="about">
        <LeftSide>
          <img src={Lamp} alt="Lamp" />
        </LeftSide>
        <RightSide>
          <div>
            <h1>O que é o Home 360?</h1>
            <p>
              Criado com o intuito de melhorar o processo de venda e aluguel de
              imóveis, o Home 360 é uma plataforma em que empresas podem criar
              tours virtuais para seus imóveis, facilitando a sua divulgação
            </p>
            <Link to="/signup">
              <button type="button">Conhecer</button>
            </Link>
          </div>
        </RightSide>
      </About>
      <Pricing id="pricing">
        <Title>Conheça nossos planos</Title>
        <Cards>
          <Card>
            <img src={Free} alt="Free icon" />
            <h1>Free</h1>
            <p>Crie até 5 projetos gratuitamente</p>
            <Link to="/signup">
              <button type="button">Comece a utilizar</button>
            </Link>
          </Card>
          <Card>
            <img src={Starter} alt="Starter icon" />
            <h1>Starter</h1>
            <p>Crie até 20 projetos por R$ 49,90</p>
            <Link to="/signup">
              <button type="button">Comece a utilizar</button>
            </Link>
          </Card>
          <Card>
            <img src={Pro} alt="Pro icon" />
            <h1>Pro</h1>
            <p>Crie até 100 projetos por R$ 99,90</p>
            <Link to="/signup">
              <button type="button">Comece a utilizar</button>
            </Link>
          </Card>
        </Cards>
      </Pricing>
      <Contact id="contact">
        <LeftSide>
          <img src={Comments} alt="Woman with comments card" />
        </LeftSide>
        <FormArea>
          <form action="">
            <h1>Entre em contato conosco!</h1>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Digite seu nome"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Digite seu email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <textarea
              placeholder="Seus comentários"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
            <button type="button">Enviar</button>
          </form>
        </FormArea>
      </Contact>
      <script>
        {window.addEventListener('scroll', () => {
          const menu = document.getElementById('menu');

          if (menu) {
            if (document.documentElement.scrollTop > 120) {
              menu.classList.add('visible');
            } else {
              menu.classList.remove('visible');
            }
          }
        })}
      </script>
    </Container>
  );
}

export default Landing;
