import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  border: 1px solid #ddd;
  cursor: grab;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;

  button {
    display: flex;
    flex-direction: column;
    border: none;
  }

  img {
    width: 100%;
    height: 120px;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  h1 {
    font-size: 15px;
    color: #555;
    text-transform: capitalize;

    display: flex;
    flex: 1;
    justify-content: center;
  }

  input {
    width: 100%;
    margin: -100px auto 0 auto;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #ddd;
    color: ${({ theme }) => theme.text.primary};
    background: #fff;
    padding: 0 12px;
  }
`;

export const MoveUpButton = styled.button`
  position: absolute;
  background: none;
  right: 4px;
  bottom: 18px;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    transform: scale(2);
  }
`;

export const MoveDownButton = styled.button`
  position: absolute;
  background: none;
  right: 4px;
  bottom: 2px;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    transform: scale(2);
  }
`;

export const Exclude = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    margin-top: 10px;
  }

  button {
    border-radius: 4px;
    font-weight: bold;
    margin-top: 10px;
    padding: 0 15px;
    border: none;
    background: #f2f2f2;

    svg {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;

  input {
    margin-top: -1px;
    height: 15px;
    width: 50px;
  }
`;
