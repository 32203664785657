import styled from 'styled-components';
import { shade } from 'polished';

import Background from '../../assets/landing/background.jpg';

export const Container = styled.div`
  width: 100%;
  background: #fff;
`;

export const Menu = styled.div`
  transition: all 0.4s;

  &.visible {
    background: #fff;
    box-shadow: 2px 1px 7px rgba(0, 0, 0, 0.2);
  }

  &.visible img {
    transition: all 0.4s;
    height: 42px;
  }

  position: fixed;
  width: 100%;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 6;

  img {
    height: 62px;
    margin-top: 15px;
  }

  div {
    a {
      margin: 0 10px;
      transition: all 0.4s;
      text-decoration: none;
      color: #312e38;
      font-size: 18px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

      &:hover {
        transform: translateY(10px);
        color: #444;
      }
    }

    a.signin {
      width: 120px;
      height: 32px;
      padding: 5px 15px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #3e348b;
      transition: all 0.4s;
      color: #3e348b;

      &:hover {
        background: #3e348b;
        border-radius: 8px;
        transform: translateY(-3px);
        color: #f8f8f8;
      }
    }
  }
`;

export const Present = styled.div`
  height: 100vh;
  width: 100%;
  background: #fff;
  display: flex;
`;

export const ImageBackground = styled.div`
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 412px) {
    display: none;
  }

  width: 40%;
  height: 100vh;
`;

export const Content = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    @media (max-width: 412px) {
      display: none;
    }

    height: 220px;
    margin-right: 20px;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  max-width: 300px;
  padding: 0 10px;

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: #312e38;
    margin-bottom: 10px;
  }

  p {
    font-size: 23px;
    font-weight: 300;
    color: #312e38;
    margin-bottom: 10px;
  }

  button {
    border: none;
    background: #3e348b;
    color: #fff;
    width: 100%;
    height: 42px;
    border-radius: 8px;
    transition: all 0.5s;
    box-shadow: 2px 2px 3px rgba(62, 52, 139, 0.5);

    font-weight: bold;
    font-size: 18px;

    &:hover {
      transform: translateY(-4px);
      border-radius: 16px;
      background: ${shade(0.3, '#3e348b')};
    }
  }
`;

export const About = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 350px;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    max-width: 450px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 32px;
      color: #312e38;
    }

    p {
      font-size: 23px;
      color: #312e38;
      font-weight: 400;
      margin: 10px 0;
    }

    button {
      border: none;
      background: #3e348b;
      color: #fff;
      width: 100%;
      height: 42px;
      border-radius: 8px;
      transition: all 0.5s;
      box-shadow: 2px 2px 3px rgba(62, 52, 139, 0.5);

      font-weight: bold;
      font-size: 18px;

      &:hover {
        transform: translateY(-4px);
        border-radius: 16px;
        background: ${shade(0.3, '#3e348b')};
      }
    }
  }
`;

export const Pricing = styled.div`
  height: 100vh;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const Title = styled.h1`
  margin-top: 15px;
  font-size: 28px;
  color: #312e38;
`;

export const Cards = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 320px;
  height: 400px;
  margin: 0 5px;
  border-radius: 8px;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 15px;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    height: 100px;
    margin-bottom: 15px;
  }

  h1 {
    color: #312e38;
  }

  p {
    font-size: 19px;
    margin: 10px 0;
    color: #312e38;
    font-weight: 400;
    text-align: center;
    max-width: 70%;
  }

  button {
    width: 260px;
    height: 32px;
    background: #3e348b;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border-radius: 6px;
    box-shadow: 2px 2px 3px rgba(62, 52, 139, 0.5);
    transition: all 0.3s;

    &:hover {
      background: ${shade(0.2, '#3e348b')};
      transform: translateY(-3px);
    }
  }
`;

export const Contact = styled.div`
  width: 100%;
  height: 100vh;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const FormArea = styled.div`
  flex: 1;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    h1 {
      color: #312e38;
    }

    input {
      width: 320px;
      height: 32px;
      border: none;
      border-radius: 6px;
      background: #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      padding: 5px 15px;
      font-size: 16px;
      color: #312e38;
      margin: 5px 0;
    }

    label {
      font-size: 21px;
      color: #312e38;
      margin: 10px 0 5px;
    }

    textarea {
      margin: 10px 0;
      width: 320px;
      height: 150px;
      border: none;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      color: #312e38;
      font-size: 16px;
    }

    button {
      border: none;
      background: #3e348b;
      color: #fff;
      width: 320px;
      height: 42px;
      border-radius: 8px;
      transition: all 0.5s;
      box-shadow: 2px 2px 3px rgba(62, 52, 139, 0.5);

      font-weight: bold;
      font-size: 18px;

      &:hover {
        transform: translateY(-4px);
        border-radius: 16px;
        background: ${shade(0.3, '#3e348b')};
      }
    }
  }
`;
