import React, { useState, useMemo, useCallback } from 'react';
import {
  Facebook,
  Link as Copy,
  Delete,
  Edit,
  Restore,
  Visibility,
} from '@material-ui/icons';
import { Switch } from '@material-ui/core';
import { toast } from 'react-toastify';
import FacebookProvider from 'react-facebook-sdk';
import {
  Container,
  Header,
  Content,
  Footer,
  Controls,
  Button,
  Code,
  FacebookShare,
  Views,
} from './styles';

import { useAuth } from '../../provider/hooks/auth';
import firestore from '../../config/firestore';
import REACT_APP_FACEBOOK_APP_ID from '../../setup';

const Card = ({
  id,
  title,
  description,
  thumbnail,
  responsable,
  code,
  isInTrash,
  views,
  isPublic,
  onDelete,
  onMoveFromTrash,
  onEdit,
  onRestore,
  canPublish,
}) => {
  const [checked, setChecked] = useState(isPublic);
  const { organization } = useAuth();

  const formattedOrgName = useMemo(() => {
    const { name } = organization.data;

    const nameLowerCase = name.toLowerCase();

    const organizationName = nameLowerCase.replace(/\s/g, '-');

    return organizationName;
  }, [organization]);

  const handleCopyLink = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = `https://home360.com.br/view/${formattedOrgName}/${code}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    toast.success(
      'Link de compartilhamento copiado para área de transferência!'
    );
  }, [formattedOrgName, code]);

  const handlePublish = useCallback(async () => {
    if (canPublish) {
      setChecked(prev => !prev);

      const docRef = firestore.collection('tours360').doc(id);
      const response = await docRef.get();
      const isPublished = response.data().publicado;
      docRef.set(
        {
          publicado: !isPublished,
        },
        { merge: true }
      );

      if (!isPublished) {
        handleCopyLink();
      }
    } else {
      toast.error(
        'O projeto não pode ser publicado pois há caminhos faltando.'
      );
    }
  }, [id, handleCopyLink, canPublish, checked]);

  return (
    <FacebookProvider appId={REACT_APP_FACEBOOK_APP_ID}>
      <Container isInTrash={isInTrash}>
        <Header background={thumbnail} />
        <Views>
          <Visibility />
          {views}
        </Views>
        <Code>
          <strong>{code}</strong>
        </Code>
        <Content>
          <header>
            <h3>{title}</h3>
            {!isInTrash && (
              <Switch
                onChange={handlePublish}
                color="primary"
                checked={checked}
                size="medium"
              />
            )}
          </header>
          <div>
            <p>{description}</p>
          </div>
          <Footer>
            <span>{responsable}</span>
            <Controls>
              {isInTrash ? (
                <>
                  <Button type="delete" onClick={onDelete}>
                    <Delete />
                  </Button>

                  <Button type="restore" onClick={onRestore}>
                    <Restore />
                  </Button>
                </>
              ) : (
                checked && (
                  <>
                    <FacebookShare
                      href={`https://home360.com.br/view/${formattedOrgName}/${code}`}
                    >
                      <Facebook color="primary" />
                    </FacebookShare>
                    <Button type="copy" onClick={handleCopyLink}>
                      <Copy />
                    </Button>
                    <Button type="delete" onClick={onMoveFromTrash}>
                      <Delete />
                    </Button>
                    <Button type="edit" onClick={onEdit}>
                      <Edit />
                    </Button>
                  </>
                )
              )}

              {!checked && !isInTrash && (
                <>
                  <Button type="delete" onClick={onMoveFromTrash}>
                    <Delete />
                  </Button>
                  <Button type="edit" onClick={onEdit}>
                    <Edit />
                  </Button>
                </>
              )}
            </Controls>
          </Footer>
        </Content>
      </Container>
    </FacebookProvider>
  );
};

export default Card;
