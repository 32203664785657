/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback } from 'react';
import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CssBaseline,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import {
  Menu,
  Dashboard,
  BusinessCenter,
  Settings,
  AccountCircle,
  Group,
  ExitToApp,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import {
  useStyles,
  Container,
  AreaHeader,
  Header,
  User,
  UserAvatar,
  MenuUser,
  MenuHeader,
  MenuUserItem,
} from './styles';
import { useAuth } from '../../provider/hooks/auth';

const Aside = ({ window, page, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [openInMobile, setOpenInMobile] = useState(false);
  const [shownMenuUser, setShownMenuUser] = useState(false);

  const { organization, updateOrg, signOut, user } = useAuth();

  const navigator = [
    { id: 1, text: 'Tour Virtual', icon: <Dashboard />, path: '/dashboard' },
    {
      id: 2,
      text: 'Meu negócio',
      icon: <BusinessCenter />,
      path: '/my-business',
    },
    { id: 3, text: 'Configurações', icon: <Settings />, path: '/config' },
    { id: 4, text: 'Meu perfil', icon: <AccountCircle />, path: '/profile' },
    { id: 5, text: 'Usuários', icon: <Group />, path: '/users' },
  ];

  const userNavigator = [
    { id: 1, text: 'Meu perfil', icon: <AccountCircle />, value: 'profile' },
    {
      id: 2,
      text: 'Sair',
      icon: <ExitToApp />,
      value: 'logout',
    },
  ];

  const handleDrawerToggleOpen = useCallback(() => {
    setOpenInMobile(!openInMobile);
  }, [openInMobile]);

  const handleUserActions = useCallback(
    value => {
      switch (value) {
        case 'logout':
          signOut();
          updateOrg({});
          break;
        case 'profile':
          history.push('/profile');
          break;
        default:
          break;
      }
    },
    [signOut, updateOrg, history]
  );

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List id="header">
        <AreaHeader>
          <img src={organization.logoPhoto || null} alt="" />
          <strong>{organization.data.name}</strong>
        </AreaHeader>
      </List>
      <List id="content">
        {navigator.map(item => (
          <ListItem button key={item.id}>
            <Link className={classes.link} to={item.path}>
              <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
              <ListItemText className={classes.title} primary={item.text} />
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Container>
        <CssBaseline />
        <AppBar color="inherit" position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggleOpen}
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
            <Header>
              <strong className={classes.page}>{page}</strong>
              <User onClick={() => setShownMenuUser(!shownMenuUser)}>
                <UserAvatar
                  hasProfilePhoto={!!user.profilePhoto}
                  src={user.profilePhoto}
                >
                  {!user.profilePhoto && user.name[0]}
                </UserAvatar>
              </User>
            </Header>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={openInMobile}
              onClose={handleDrawerToggleOpen}
              classes={{ paper: classes.drawerPaper }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main
          onClick={() => setShownMenuUser(false)}
          className={classes.content}
        >
          <div className={classes.toolbar}>{children}</div>
        </main>
      </Container>

      {shownMenuUser && (
        <MenuUser>
          <MenuHeader>
            <UserAvatar src={user.profilePhoto}>
              {!user.profilePhoto && user.name[0]}
            </UserAvatar>
            <strong>{user.name}</strong>
            <span>{user.email}</span>
          </MenuHeader>
          {userNavigator.map(userNav => (
            <MenuUserItem
              key={userNav.id}
              onClick={() => handleUserActions(userNav.value)}
            >
              <span>{userNav.text}</span>
              {userNav.icon}
            </MenuUserItem>
          ))}
        </MenuUser>
      )}
    </>
  );
};

export default Aside;
